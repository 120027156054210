import React from 'react';
import { motion } from 'framer-motion';

const AnimatedSprayButton = ({ spraying }) => {
  return (
    <div className="relative flex flex-col items-center">
      <div className="perfume-bottle bg-gray-300 w-32 h-40 rounded-lg relative mb-4">
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="cap bg-warmSand w-14 h-8 rounded-md absolute -top-8 left-1/2 transform -translate-x-1/2 cursor-pointer"
          animate={spraying ? { y: 16 } : { y: 0 }}
          transition={{ duration: 0.5 }}
        />
        <div className="body bg-gray-400 w-full h-full rounded-lg absolute bottom-0 left-0"></div>
      </div>
      {spraying && (
        <motion.div
          className="spray-dots absolute"
          initial={{ opacity: 0, x: 60, y: -30 }}
          animate={{ opacity: 1, x: 120 }}
          transition={{ duration: 1.5, repeat: 1, repeatType: 'mirror' }}
        >
          <div className="circle bg-blue-300 w-4 h-4 rounded-full mb-2"></div>
          <div className="circle bg-blue-400 w-3 h-3 rounded-full mb-2"></div>
          <div className="circle bg-blue-500 w-2 h-2 rounded-full"></div>
        </motion.div>
      )}
    </div>
  );
};

export default AnimatedSprayButton;
