import React, { useEffect, useState } from 'react';
import '../App.css'; // Import global CSS for animations
import './BottleWithPerfume.css'; // Import specific CSS for this component

const BottleWithPerfume = ({ colorTone, fillLevel }) => {
  const [randomFill, setRandomFill] = useState(0);
  const [bubblesVisible, setBubblesVisible] = useState(false);

  useEffect(() => {
    setRandomFill(fillLevel);

    const bubbleDelay = setTimeout(() => {
      setBubblesVisible(true);
    }, 1000);

    return () => clearTimeout(bubbleDelay);
  }, [fillLevel]);

  return (
    <div className="container">
      <div className="bottleCap"></div>
      <div className="bottleBody">
        <div
          className="perfume-fill"
          style={{ height: `${randomFill}%`, backgroundColor: colorTone }}
        ></div>
        {bubblesVisible && (
          <div className="bubblesContainer">
            {[...Array(5)].map((_, i) => (
              <div
                key={i}
                className="bubble"
                style={{
                  animationDelay: `${Math.random() * 3}s`,
                  animationDuration: `${Math.random() * 4 + 2}s`,
                  width: `${Math.random() * 5 + 3}px`,
                  height: `${Math.random() * 5 + 3}px`,
                  left: `${Math.random() * 100}%`,
                }}
              ></div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BottleWithPerfume;
