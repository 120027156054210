import React from 'react';
import './CustomModal.css'; // Linking to new external CSS file

const CustomModal = ({ title, content, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="custom-modal-overlay">
      <div className="custom-modal-container">
        <h2 className="custom-modal-title">{title}</h2>
        <div className="custom-modal-content">
          {content}
        </div>
        <button onClick={onClose} className="custom-modal-close-btn">&times;</button>
      </div>
    </div>
  );
};

export default CustomModal;
