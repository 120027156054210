import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(null); // Store product to edit
  const [newProduct, setNewProduct] = useState({
    brand: "",
    model: "",
    sizes: [
      { size: 5, price: "" },
      { size: 10, price: "" },
      { size: 20, price: "" },
    ],
    gender: "manly",
    season: "Winter", // Default season can be adjusted
    stock: "",
    description: "", // Add description field here
    images: [], // Multiple images
  });

  const [showModal, setShowModal] = useState(false); // State for modal
  const [editMode, setEditMode] = useState(false); // State for edit mode
  const [loading, setLoading] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationSuccess, setNotificationSuccess] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1); // State to control zoom level
  const [canvas, setCanvas] = useState(null); // State to store the canvas context

  const navigate = useNavigate();

  const brandsAndModels = {
    Amouage: ["Epic Man", "Gold Woman"],
    Armani: [
      "Code",
      "Emporio",
      "Aqua di Gio",
      "Aqua di Gio Profumo",
      "Stronger with you",
      "Emporio Femme",
      "Code for her",
      "Si",
      "Si Passione",
    ],
    Azzaro: ["Wanted"],
    Boss: [
      "Bottled",
      "The Scent for him",
      "Orange",
      "Ma Vie",
      "The Scent for her",
    ],
    Burberry: ["Hero", "Her", "Goddess"],
    Bulgari: ["Aqua"],
    Calvin_Klein: ["Eternity Herren Variante", "Euphoria", "Eternity"],
    Chanel: [
      "Bleu",
      "Allure Homme Sport",
      "Allure",
      "Nr. 5",
      "Chance",
      "Coco",
      "Gabrielle",
      "Coco Mademoiselle",
      "Coco Noir",
    ],
    Carolina_Herrera: [
      "Bad Boy",
      "Good Girl",
      "Good Girl Blush",
      "Very Good Girl",
      "212",
      "212 VIP",
      "212 Sexy",
      "212 Rose",
    ],
    Cartier: ["La Panthere"],
    Chloe: ["Chloe", "Love Story", "Nomad", "Love"],
    Creed: [
      "Aventus",
      "Spring Flower",
      "Aventus Femme",
      "Silver Mountain Water",
      "Virgin Island Water",
      "Viking",
    ],
    Dior: [
      "Sauvage 2015",
      "Sauvage Elixier",
      "Fahrenheit",
      "Homme",
      "Miss Dior",
      "Addict",
      "J'adore",
      "Joy",
      "Poison Girl",
      "Hypnotic Poison",
      "Pure Poison",
    ],
    Dolce_Gabbana: [
      "The One for him",
      "K",
      "Homme",
      "The One Femme",
      "The Only One",
    ],
    Diesel: ["Bad", "Only the Brave"],
    Donna_Karan: ["DKNY"],
    Gaultier: [
      "Le Male",
      "Le Male Intense",
      "Divine",
      "Classique",
      "Scandal",
      "Gaultier 2",
    ],
    Ginza: ["Shiseido"],
    Givenchy: ["Irresistible"],
    Gucci: ["Guilty", "Flora", "Rush", "Bloom"],
    Guerlain: ["La Petit Robe Noir"],
    Hermes: ["Terre de Hermes", "Un Jardin sur le Nil", "H24"],
    Joe_Malone: ["Velvet Rose"],
    Lancome: [
      "La Vie Est Belle",
      "Miracle",
      "Tresor",
      "La Nuit Tresor",
      "Idole",
    ],
    Marly: ["Layton", "Delina"],
    Michael_Kors: ["Sexy Amber"],
    Narciso_Rodriguez: ["For Her", "Rouge"],
    Paco_Rabanne: [
      "Phantom",
      "One Million",
      "One Million Elixier",
      "One Million Prive",
      "Invictus",
      "Pure XS",
      "Fame",
      "Lady Million",
      "Lady Million Empire",
      "Olympea",
    ],
    Prada: ["Luna Rossa Carbon", "Paradoxe"],
    Thierry_Mugler: ["Angel", "Alien Goddess", "Alien", "Aura"],
    Valentino: ["Born in Roma Herren", "Voce Viva"],
    Victor_Rolf: ["Spice Bomb", "Flowerbomb", "Bonbon"],
    Versace: ["Eros", "Eros for her"],
    Yves_SL: [
      "MSLF",
      "Homme",
      "Y",
      "Libre Intense",
      "Manifesto",
      "Opium",
      "Black Opium",
      "Libre",
    ],
    Baccarat: ["Rouge 540"],
    Kilian: ["Voulez Vous Couchez Avec Moi", "Vodka on the Rocks"],
    Louis_Vuitton: [
      "Maitre Noire",
      "Nuit de Feu",
      "Afternoon Swim",
      "Cactus Garden",
      "Apogee",
      "Imagination",
      "Ombre Nomade",
      "L'Immensite",
      "On the Beach",
      "Meteore",
      "Spell on You",
    ],
    Le_Labo: ["Matcha 26", "Santal 33", "Another 13"],
    Montale: ["Intense Cafe", "Arabians Tonka", "Black Aoud"],
    Nasomatto: ["Black Afgano", "Sadonaso"],
    Tom_Ford: [
      "Soleil Neige",
      "Costa Azzurra",
      "Tuscan Leather",
      "Lost Cherry",
      "Fucking Fabulous",
      "Oud Wood",
      "Black Orchid",
      "Rose Prick",
      "Tobacco Oud",
      "Neroli Portofino",
      "Tobacco Vanille",
      "Noir Extreme",
      "Soleil Blanc",
      "Ombre Leather",
      "Mandarino di Amalfi",
      "Electric Cherry",
      "Cherry Smoke",
    ],
    Xerjoff: ["Erba Pura", "Naxos 1861"],
  };

  useEffect(() => {
    const token = localStorage.getItem("adminToken");
    if (!token) {
      navigate("/admin"); // Navigate to login if no token
    } else {
      fetchProducts(); // Fetch products when component mounts
    }
  }, [navigate]);

  const fetchProducts = async () => {
    const token = localStorage.getItem("adminToken"); // Ensure token is retrieved
    try {
      const response = await fetch("/api/products", {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request headers
        },
      });
      const data = await response.json();
      setProducts(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // Handle input change for the product form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle size and price change
  const handleSizeChange = (index, field, value) => {
    const updatedSizes = [...newProduct.sizes];
    updatedSizes[index][field] = value;
    setNewProduct((prev) => ({
      ...prev,
      sizes: updatedSizes,
    }));
  };

  // AdminDashboard Component

  const handleSaveProduct = async () => {
    const token = localStorage.getItem("adminToken"); // Retrieve token
    if (!token) {
      alert("Session expired, please log in again.");
      return;
    }

    if (
      !newProduct.brand ||
      !newProduct.model ||
      !newProduct.sizes ||
      !newProduct.stock
    ) {
      alert("Please fill in all required fields (Brand, Model, Sizes, Stock).");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("brand", newProduct.brand);
      formData.append("model", newProduct.model);
      formData.append(
        "gender",
        newProduct.gender === "man" ? "manly" : newProduct.gender
      );
      formData.append("season", newProduct.season);
      formData.append("stock", newProduct.stock);
      formData.append("description", newProduct.description); // Ensure description is included
      formData.append("sizes", JSON.stringify(newProduct.sizes));
      formData.append("images", newProduct.images[0]);

      const response = await fetch(
        editMode ? `/api/products/${currentProduct._id}` : "/api/products",
        {
          method: editMode ? "PATCH" : "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`, // Pass token here
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        if (editMode) {
          setProducts((prevProducts) =>
            prevProducts.map((product) =>
              product._id === result._id ? result : product
            )
          );
        } else {
          setProducts((prevProducts) => [...prevProducts, result]);
        }
        setShowModal(false);
        setEditMode(false);
      } else {
        alert("Failed to save the product.");
      }
    } catch (error) {
      console.error("Error saving product:", error);
      alert("An error occurred while saving the product.");
    }
  };

  const handleDeleteProduct = (productId) => {
    setCurrentProduct({ _id: productId });
    setEditMode(false);
    setShowConfirmationModal(true); // Open confirmation modal directly for delete
  };

  const confirmAction = async () => {
    if (!editMode) {
      await deleteProduct(currentProduct._id); // Delete the product only if not in edit mode
    }
    setShowConfirmationModal(false); // Close confirmation modal
  };

  const deleteProduct = async (productId) => {
    try {
      const response = await fetch(`/api/products/${productId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        setProducts(products.filter((p) => p._id !== productId));
      } else {
        alert("Failed to delete product.");
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const handleEditProduct = (product) => {
    setCurrentProduct(product);
    setNewProduct({
      brand: product.brand,
      model: product.model,
      sizes: product.sizes,
      gender: product.gender,
      season: product.season,
      stock: product.stock,
      description: product.description || "", // Ensure description is included here
      images: product.images || [],
    });
    setEditMode(true);
    setShowModal(true);

    // Load original image data for editing
    if (product.images.length > 0) {
      const img = new Image();
      img.src = `/uploads/${product.gender.toLowerCase()}/${product.brand
        .replace(/\s+/g, "_")
        .toLowerCase()}/${product.model.replace(/\s+/g, "_").toLowerCase()}/${
        product.images[0]
      }`;
      img.onload = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        setCanvas(ctx);
        drawImageOnCanvas(ctx, img, zoomLevel);
      };
    }
  };

  // After editing or deleting, show notification
  const showNotification = (message, success) => {
    setNotificationMessage(message);
    setNotificationSuccess(success);
    setTimeout(() => setNotificationMessage(""), 3000); // Hide notification after 3 seconds
  };
  // Inside the Save button, after clicking "Save Changes," trigger the confirmation modal
  const handleSaveConfirmation = () => {
    setShowModal(false); // Close edit modal
    setShowConfirmationModal(true); // Open confirmation modal
  };

  // Ref for the canvas
  const canvasRef = useRef(null);

  const drawImageOnCanvas = (ctx, img, zoom) => {
    const canvas = ctx.canvas;

    // Clear the canvas and fill with white background
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Calculate scaled dimensions for the zoomed image, maintaining aspect ratio
    let scaledWidth, scaledHeight;

    // Check if image is wider than it is tall
    if (img.width > img.height) {
      scaledWidth = canvas.width * zoom;
      scaledHeight = (img.height / img.width) * scaledWidth;
    } else {
      scaledHeight = canvas.height * zoom;
      scaledWidth = (img.width / img.height) * scaledHeight;
    }

    // Center the image on the canvas
    const xPos = (canvas.width - scaledWidth) / 2;
    const yPos = (canvas.height - scaledHeight) / 2;

    // Draw the image on the canvas
    ctx.drawImage(img, xPos, yPos, scaledWidth, scaledHeight);
  };

  const removeImage = () => {
    setNewProduct((prev) => ({
      ...prev,
      images: [],
    }));

    // Clear the canvas
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  // Function to handle the image zooming and rendering on canvas

  // Function to handle the image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (!file) return; // If no file is selected, return early

    const img = new Image();
    const reader = new FileReader();

    reader.onload = (event) => {
      img.src = event.target.result;

      img.onload = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        setCanvas(ctx);

        canvas.width = 500;
        canvas.height = 500;

        drawImageOnCanvas(ctx, img, 1);

        const imageWithWhiteBackground = adjustImageToSquareWithBackground(
          ctx,
          img
        );
        const dataURL = imageWithWhiteBackground.toDataURL("image/png");

        fetch(dataURL)
          .then((res) => res.blob())
          .then((blob) => {
            const editedImageFile = new File([blob], "processed-image.png", {
              type: "image/png",
            });

            // Clear previous images and only keep the new one
            setNewProduct((prev) => ({
              ...prev,
              images: [editedImageFile], // Replace with the new uploaded image only
            }));
          });
      };
    };

    reader.readAsDataURL(file);
  };

  const handleZoomChange = (newZoom) => {
    setZoomLevel(newZoom);

    const canvas = canvasRef.current;
    if (canvas && newProduct.images.length > 0) {
      const img = new Image();
      // Use the original image source
      img.src = `/uploads/${currentProduct.gender.toLowerCase()}/${currentProduct.brand
        .replace(/\s+/g, "_")
        .toLowerCase()}/${currentProduct.model
        .replace(/\s+/g, "_")
        .toLowerCase()}/${currentProduct.images[0]}`;
      img.onload = () => {
        const ctx = canvas.getContext("2d");
        drawImageOnCanvas(ctx, img, newZoom);
      };
    }
  };

  const saveEditedImage = () => {
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL("image/png"); // Use PNG to maintain quality

    fetch(dataURL)
      .then((res) => res.blob())
      .then((blob) => {
        // Replace the original file with the edited version without creating a new file
        const editedImageFile = new File([blob], newProduct.images[0].name, {
          type: "image/png",
        });
        setNewProduct((prev) => ({
          ...prev,
          images: [editedImageFile], // Replace with the new edited image
        }));
      });
  };

  // Utility function to fit the image into a square canvas with white background
  const adjustImageToSquareWithBackground = (ctx, img) => {
    // Clear the canvas and fill with white background
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    // Calculate the image size and position to fit into the square canvas
    let scaledWidth, scaledHeight;
    if (img.width > img.height) {
      scaledWidth = ctx.canvas.width;
      scaledHeight = (img.height / img.width) * scaledWidth;
    } else {
      scaledHeight = ctx.canvas.height;
      scaledWidth = (img.width / img.height) * scaledHeight;
    }

    const xPos = (ctx.canvas.width - scaledWidth) / 2;
    const yPos = (ctx.canvas.height - scaledHeight) / 2;

    // Draw the image on the canvas
    ctx.drawImage(img, xPos, yPos, scaledWidth, scaledHeight);

    return ctx.canvas;
  };

  return (
    <div className="admin-dashboard-page bg-lavaBlack text-silverGray min-h-screen p-8">
      <div className="w-full max-w-5xl mx-auto">
        <h1 className="text-4xl font-bold mb-6 text-center">Admin Dashboard</h1>
        <button
          onClick={() => navigate("/admin/orders")}
          className="bg-warmSand text-lavaBlack py-2 px-4 rounded-lg hover:bg-[#f0d8b5] transition"
        >
          Manage Orders
        </button>
        <button
          onClick={() => navigate("/admin/analytics")} // Navigate to the Analytics page
          className="bg-warmSand text-lavaBlack py-2 px-4 ml-4 rounded-lg hover:bg-[#f0d8b5] transition"
        >
          View Analytics
        </button>

        <button
          onClick={() => navigate("/admin/product-ordering")}
          className="bg-warmSand text-lavaBlack py-2 px-4 ml-4 rounded-lg hover:bg-[#f0d8b5] transition"
        >
          Reorder Products
        </button>

        <div className="flex justify-between mb-6">
          <h2 className="text-2xl font-bold">All Products</h2>
          <button
            onClick={() => {
              setShowModal(true);
              setEditMode(false);
              setNewProduct({
                brand: "",
                model: "",
                sizes: [
                  { size: 5, price: "" },
                  { size: 10, price: "" },
                  { size: 20, price: "" },
                ],
                gender: "manly",
                season: "Winter",
                stock: "",
                images: [],
              });
            }}
            className="bg-warmSand text-lavaBlack py-2 px-4 rounded-lg hover:bg-[#f0d8b5] transition"
          >
            Add Product
          </button>
        </div>

        {/* Products Grid */}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {!loading && products.length > 0 ? (
            products.map((product) => {
              const imageGender = product.gender
                ? product.gender.toLowerCase()
                : "";
              const brand = product.brand
                ? product.brand.replace(/\s+/g, "_").toLowerCase()
                : "";
              const model = product.model
                ? product.model.replace(/\s+/g, "_").toLowerCase()
                : "";
              const imageUrl =
                product.images.length > 0 && brand && model
                  ? `/uploads/${imageGender}/${brand}/${model}/${product.images[0]}`
                  : null;

              // Function to truncate the description to three words
              const truncateDescription = (description) => {
                if (!description) return ""; // Handle cases where description is undefined or empty
                const words = description.split(" ");
                return words.length > 3
                  ? words.slice(0, 3).join(" ") + "..."
                  : description;
              };

              return (
                <div
                  key={product._id}
                  className="bg-warmSand p-6 rounded-lg shadow-md text-center"
                >
                  {/* Image section */}
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt={product.name}
                      className="object-cover h-48 w-full mb-4"
                    />
                  ) : (
                    <div className="h-48 bg-gray-300 mb-4">No Image</div>
                  )}
                  {/* Product info */}
                  <h2 className="text-xl font-bold text-black">
                    {product.name}
                  </h2>
                  {/* Truncated Description */}
                  <p className="text-black">
                    Description: {truncateDescription(product.description)}
                  </p>
                  <p className="text-black">Prices:</p>
                  <p className="text-black">
                    {product.sizes
                      .map((sizeObj) => `${sizeObj.size} ml: $${sizeObj.price}`)
                      .join(", ")}
                  </p>{" "}
                  {/* Display sizes and prices */}
                  <p className="text-black">Gender: {product.gender}</p>
                  <p className="text-black">Season: {product.season}</p>
                  <p className="text-black">Stock: {product.stock} ml</p>
                  <p className="text-black">Sales: {product.salesCount || 0}</p>

                  {/* Edit and Delete Buttons */}
                  <div className="mt-4 flex justify-between">
                    <button
                      onClick={() => handleEditProduct(product)}
                      className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteProduct(product._id)}
                      className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No products found.</p>
          )}
        </div>

        {/* Modal for Adding/Editing Product */}
        {/* Modal for Adding/Editing Product */}
        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-75 overflow-y-auto flex justify-center z-50">
            <div className="bg-lavaBlack p-8 rounded-lg shadow-lg w-full max-w-2xl my-8 text-silverGray">
              <h2 className="text-2xl font-bold mb-4">
                {editMode ? "Edit Product" : "Add New Product"}
              </h2>
              <div className="space-y-4">
                {/* Gender Selection */}
                <select
                  name="gender"
                  value={newProduct.gender}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-lavaBlack text-silverGray border border-silverGray rounded-lg"
                >
                  <option value="manly">Manly</option>
                  <option value="feminine">Feminine</option>
                  <option value="unisex">Unisex</option>
                </select>

                {/* Brand Selection */}
                <select
                  name="brand"
                  value={newProduct.brand}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-lavaBlack text-silverGray border border-silverGray rounded-lg"
                >
                  <option value="">Select Brand</option>
                  {Object.keys(brandsAndModels).map((brand) => (
                    <option key={brand} value={brand}>
                      {brand}
                    </option>
                  ))}
                </select>

                {/* Model Selection */}
                <select
                  name="model"
                  value={newProduct.model}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-lavaBlack text-silverGray border border-silverGray rounded-lg"
                  disabled={!newProduct.brand}
                >
                  <option value="">Select Model</option>
                  {newProduct.brand &&
                    brandsAndModels[newProduct.brand].map((model) => (
                      <option key={model} value={model}>
                        {model}
                      </option>
                    ))}
                </select>

                {/* Season Selection */}
                <select
                  name="season"
                  value={newProduct.season}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-lavaBlack text-silverGray border border-silverGray rounded-lg"
                >
                  <option value="">Select Season</option>
                  <option value="Winter">Winter</option>
                  <option value="Summer">Summer</option>
                  <option value="All Seasons">All Seasons</option>
                </select>

                {/* Description Input */}
                <textarea
                  name="description"
                  value={newProduct.description}
                  onChange={handleInputChange}
                  placeholder="Enter a short description of the perfume"
                  className="w-full p-2 bg-lavaBlack text-silverGray border border-silverGray rounded-lg"
                />

                {/* Sizes and Prices */}
                <div>
                  <h3 className="text-lg font-bold">Sizes and Prices:</h3>
                  {newProduct.sizes.map((sizeObj, index) => (
                    <div key={index} className="flex space-x-4">
                      <span>{sizeObj.size} ml</span>
                      <input
                        type="number"
                        name={`price-${sizeObj.size}`}
                        placeholder="Price"
                        value={sizeObj.price}
                        onChange={(e) =>
                          handleSizeChange(index, "price", e.target.value)
                        }
                        className="w-full p-2 bg-lavaBlack text-silverGray border border-silverGray rounded-lg"
                      />
                    </div>
                  ))}
                </div>

                {/* Stock Input */}
                <input
                  name="stock"
                  placeholder="Stock"
                  value={newProduct.stock}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-lavaBlack text-silverGray border border-silverGray rounded-lg"
                />

                {/* Image Upload */}
                <div className="image-upload-container">
                  <input
                    type="file"
                    name="images"
                    accept="image/*"
                    multiple
                    onChange={handleImageUpload}
                    className="w-full p-2 bg-lavaBlack text-silverGray border border-silverGray rounded-lg"
                  />

                  {/* Canvas for Image Preview */}
                  <canvas
                    ref={canvasRef}
                    className="image-canvas bg-white"
                    width={300}
                    height={300}
                  />

                  {/* Remove Image Button */}
                  {editMode && newProduct.images.length > 0 && (
                    <div className="relative mt-4">
                      <button
                        className="absolute bg-red-500 text-white rounded-full px-2 py-1"
                        style={{ top: "-30px", right: "-30px" }}
                        onClick={removeImage}
                      >
                        X
                      </button>
                    </div>
                  )}

                  {/* Zoom Controls */}
                  <div className="zoom-controls flex space-x-2 mt-2">
                    <button
                      className="bg-gray-500 text-white px-2 py-1 rounded-lg"
                      onClick={() =>
                        handleZoomChange(Math.max(zoomLevel - 0.1, 0.2))
                      }
                    >
                      -
                    </button>
                    <input
                      type="range"
                      min="0.2"
                      max="2"
                      step="0.1"
                      value={zoomLevel}
                      onChange={(e) =>
                        handleZoomChange(parseFloat(e.target.value))
                      }
                      className="flex-grow"
                    />
                    <button
                      className="bg-gray-500 text-white px-2 py-1 rounded-lg"
                      onClick={() =>
                        handleZoomChange(Math.min(zoomLevel + 0.1, 2))
                      }
                    >
                      +
                    </button>
                  </div>

                  {/* Save Edited Image Button */}
                  <button
                    className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition mt-2"
                    onClick={saveEditedImage}
                  >
                    Save Edited Image
                  </button>
                </div>
              </div>

              {/* Buttons */}
              <div className="flex justify-end space-x-4 mt-6">
                <button
                  className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition"
                  onClick={handleSaveProduct}
                >
                  {editMode ? "Save Changes" : "Add Product"}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Modal for confirming Edit or Delete */}
        {showConfirmationModal && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-lavaBlack p-8 rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2 text-silverGray">
              <h2 className="text-2xl font-bold mb-4">Are you sure?</h2>
              <p>
                {editMode
                  ? "Are you sure you want to save changes to this product?"
                  : "Are you sure you want to delete this product?"}
              </p>

              <div className="flex justify-end space-x-4 mt-6">
                <button
                  className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600 transition"
                  onClick={() => setShowConfirmationModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition"
                  onClick={confirmAction} // Confirm save or delete
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Notification Modal for Success/Error Messages */}
        {notificationMessage && (
          <div className="fixed top-0 left-0 w-full flex justify-center z-50">
            <div
              className={`p-4 rounded-md shadow-lg mt-4 ${
                notificationSuccess
                  ? "bg-green-500 text-white"
                  : "bg-red-500 text-white"
              }`}
            >
              <p>{notificationMessage}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
