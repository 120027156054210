import React, { useState } from 'react';
import './OrderStatus.css';

const OrderStatus = () => {
  const [email, setEmail] = useState('');
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);

  // Fetch orders by email
  const handleCheckOrder = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`/api/orders/by-email/${email}`);
      if (!response.ok) {
        throw new Error('No orders found');
      }
      const data = await response.json();
      setOrders(data); // Set all orders
      setError(null);
    } catch (error) {
      setError('No orders found or an error occurred.');
      setOrders([]);
    }
  };

  return (
    <div className="order-status-page bg-lavaBlack text-silverGray min-h-screen p-8 flex flex-col items-center">
      <h1 className="text-4xl font-bold mb-6 text-center">Check Your Order Status</h1>

      <form onSubmit={handleCheckOrder} className="space-y-4 w-full max-w-lg mb-8">
        <div className="form-group">
          <label className="block mb-2 text-silverGray">Your Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 bg-lavaBlack text-silverGray border border-silverGray rounded-lg focus:border-warmSand"
            required
          />
        </div>

        <button
          type="submit"
          className="bg-warmSand text-lavaBlack py-2 px-4 rounded-lg hover:bg-[#f0d8b5] transition"
        >
          Check Status
        </button>
      </form>

      {error && <p className="text-red-500 mt-4">{error}</p>}

      {/* Display Orders if available */}
      {orders.length > 0 && (
        <div className="w-full max-w-3xl">
          {orders.map((order, index) => (
            <div key={order._id} className="order-details mb-6 p-4 bg-white rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold mb-4">Order #{index + 1}</h2>
              <p><strong>Status:</strong> {order.status}</p>
              <p><strong>Note:</strong> {order.note || 'No note available'}</p>
              <p><strong>Date:</strong> {new Date(order.createdAt).toLocaleDateString()}</p>
              <h3 className="text-xl font-bold mt-4 mb-2">Products:</h3>
              {order.products.map((product, index) => (
                <div key={index} className="border-b pb-2 mb-2">
                  <p><strong>Product Name:</strong> {product.name}</p>
                  <p><strong>Quantity:</strong> {product.quantity}</p>
                  <p><strong>Size:</strong> {product.size}</p>
                  <p><strong>Price:</strong> ${product.price}</p>
                </div>
              ))}
              <p><strong>Total Price:</strong> ${order.products.reduce((total, product) => total + product.price * product.quantity, 0).toFixed(2)}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OrderStatus;
