import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSession } from '../context/SessionContext';

const ProtectedRoute = ({ element }) => {
  const { token, loading } = useSession();

  // Add loading check before redirecting
  if (loading) {
    return null; // or a loading spinner
  }

  return token ? element : <Navigate to="/admin" />;
};

export default ProtectedRoute;
