import React, { createContext, useContext } from 'react';
import useAnalytics from '../hooks/useAnalytics';

// Create a context
const AnalyticsContext = createContext();

export const useAnalyticsContext = () => useContext(AnalyticsContext);

const AnalyticsProvider = ({ children }) => {
  const { logEvent } = useAnalytics();

  return (
    <AnalyticsContext.Provider value={{ logEvent }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
