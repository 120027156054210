import React, { useEffect, useState } from "react";
import { useSession } from "../context/SessionContext";
import { useNavigate } from "react-router-dom";

const OrderManagement = () => {
  const { token } = useSession(); // Use token from context
  const [orders, setOrders] = useState([]);
  const [updatedOrders, setUpdatedOrders] = useState([]); // To track updated orders
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [modalMessage, setModalMessage] = useState(""); // State for modal message
  const [sortBy, setSortBy] = useState("time"); // Sorting state
  const [filterByStatus, setFilterByStatus] = useState("all"); // Filter by status
  const [editOrder, setEditOrder] = useState(null); // State for editing order
  const [confirmDelete, setConfirmDelete] = useState(null); // State for order to be deleted
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const ordersPerPage = 30; // Orders per page

  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      const fetchOrders = async () => {
        try {
          const response = await fetch(
            `/api/orders?page=${currentPage}&limit=${ordersPerPage}&filterByStatus=${filterByStatus}&sortBy=${sortBy}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(
              `HTTP error! Status: ${response.status}, Message: ${errorText}`
            );
          }

          const data = await response.json();

          setTotalPages(data.totalPages);

          // Use the populated product data directly
          const enrichedOrders = data.orders.map((order) => {
            const products = order.products.map((product) => {
              // Merge product data with the existing product object
              return { ...product, ...product.productId };
            });
            return { ...order, products };
          });

          setOrders(enrichedOrders);

          setOrders(enrichedOrders);
        } catch (error) {
          console.error("Error fetching orders:", error);
        }
      };

      fetchOrders();
    }
  }, [token, currentPage, filterByStatus, sortBy]);

  // Pagination controls
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1); // Reset to page 1 when filters change
  }, [filterByStatus, sortBy]);

  const handleStatusChange = (orderId, newStatus) => {
    // Update the `orders` state to reflect the changed status
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order._id === orderId ? { ...order, status: newStatus } : order
      )
    );

    // Update the `updatedOrders` state to track the changed status
    setUpdatedOrders((prev) => {
      const existingOrderIndex = prev.findIndex(
        (order) => order._id === orderId
      );
      if (existingOrderIndex !== -1) {
        prev[existingOrderIndex].status = newStatus;
        return [...prev];
      } else {
        return [...prev, { _id: orderId, status: newStatus }];
      }
    });
  };

  const submitStatusChanges = async () => {
    try {
      const formattedOrders = updatedOrders.map((order) => ({
        _id: order._id,
        status: order.status,
      }));

      const response = await fetch("/api/orders/update-status", {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ orders: formattedOrders }),
      });

      if (response.ok) {
        setModalMessage("Order statuses updated successfully.");
        setUpdatedOrders([]);
      } else {
        setModalMessage("Failed to update order statuses.");
      }
    } catch (error) {
      console.error("Error updating order statuses:", error);
      setModalMessage("An error occurred while updating statuses.");
    } finally {
      setShowModal(true);
      setTimeout(() => setShowModal(false), 3000); // Hide modal after 3 seconds
    }
  };

  const handleDeleteOrder = async (orderId) => {
    try {
      const response = await fetch(`/api/orders/${orderId}`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.ok) {
        setModalMessage("Order deleted successfully.");
        setOrders(orders.filter((order) => order._id !== orderId));
      } else {
        setModalMessage("Failed to delete the order.");
      }
    } catch (error) {
      console.error("Error deleting order:", error);
      setModalMessage("An error occurred while deleting the order.");
    } finally {
      setShowModal(true);
      setTimeout(() => setShowModal(false), 3000);
    }
  };

  const handleEditOrder = (order) => {
    setEditOrder(order);
  };

  const saveEdits = async () => {
    try {
      const response = await fetch(`/api/orders/${editOrder._id}/note`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ note: editOrder.note }), // Send the note to be updated
      });

      if (response.ok) {
        setModalMessage("Order updated successfully.");
        setOrders(
          orders.map((order) =>
            order._id === editOrder._id ? editOrder : order
          )
        );
        setEditOrder(null);
      } else {
        setModalMessage("Failed to update the order.");
      }
    } catch (error) {
      console.error("Error updating order:", error);
      setModalMessage("An error occurred while updating the order.");
    } finally {
      setShowModal(true);
      setTimeout(() => setShowModal(false), 3000);
    }
  };

  // Sorting and Filtering
  const filteredAndSortedOrders = orders
    .filter(
      (order) => filterByStatus === "all" || order.status === filterByStatus
    )
    .sort((a, b) => {
      if (sortBy === "time") {
        return new Date(b.createdAt) - new Date(a.createdAt); // Sort by time (newest first)
      } else if (sortBy === "amount") {
        const amountA = a.products.reduce(
          (sum, product) => sum + product.price * product.quantity,
          0
        );
        const amountB = b.products.reduce(
          (sum, product) => sum + product.price * product.quantity,
          0
        );
        return amountB - amountA; // Sort by order amount (highest first)
      }
      return 0;
    });

  // Helper function to get color for status
  const getStatusColor = (status) => {
    switch (status) {
      case "Processing":
        return "bg-yellow-500 text-black";
      case "Sent":
        return "bg-blue-500 text-white";
      case "Delivered":
        return "bg-green-500 text-white";
      default:
        return "bg-gray-500 text-white";
    }
  };

  return (
    <div className="order-management p-8 min-h-screen bg-lavaBlack">
      <h1 className="text-4xl font-bold mb-6 text-center text-silverGray">
        Order Management
      </h1>

      {/* Filters and Sorting */}
      <div className="flex justify-between mb-6">
        <div>
          <label className="mr-2 text-silverGray">Sort By:</label>
          <select
            className="bg-lavaBlack text-silverGray p-2 rounded-md"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="time">Time Submitted</option>
            <option value="amount">Order Amount</option>
          </select>
        </div>

        <div>
          <label className="mr-2 text-silverGray">Filter by Status:</label>
          <select
            className="bg-lavaBlack text-silverGray p-2 rounded-md"
            value={filterByStatus}
            onChange={(e) => setFilterByStatus(e.target.value)}
          >
            <option value="all">All</option>
            <option value="Processing">Processing</option>
            <option value="Sent">Sent</option>
            <option value="Delivered">Delivered</option>
          </select>
        </div>
      </div>

      {filteredAndSortedOrders.length > 0 ? (
        <>
          <button
            onClick={() => navigate("/admin/dashboard")}
            className="bg-warmSand text-lavaBlack py-2 px-4 mb-4 rounded-lg hover:bg-[#f0d8b5] transition"
          >
            Back to Dashboard
          </button>

          <button
            onClick={submitStatusChanges}
            className="mb-4 bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition"
          >
            Submit Status Changes
          </button>

          <div className="space-y-6">
            {filteredAndSortedOrders.map((order) => (
              <div
                key={order._id}
                className="bg-white rounded-lg shadow-md p-4 border border-gray-300"
              >
                <table className="min-w-full bg-white rounded-lg border-separate border-spacing-y-2">
                  <thead>
                    <tr>
                      <th className="p-2 text-left text-silverGray border-r border-gray-300">
                        Time
                      </th>
                      <th className="p-2 text-left text-silverGray border-r border-gray-300">
                        Customer
                      </th>
                      <th className="p-2 text-left text-silverGray border-r border-gray-300">
                        Email
                      </th>
                      <th className="p-2 text-left text-silverGray border-r border-gray-300">
                        Address
                      </th>
                      <th className="p-2 text-left text-silverGray w-1/3 border-r border-gray-300">
                        Products
                      </th>
                      <th className="p-2 text-left text-silverGray border-r border-gray-300">
                        Shipping Option
                      </th>
                      <th className="p-2 text-left text-silverGray border-r border-gray-300">
                        Shipping Cost
                      </th>
                      <th className="p-2 text-left text-silverGray border-r border-gray-300">
                        Shipment ID
                      </th>
                      <th className="p-2 text-left text-silverGray">
                        Status & Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-black">
                      <td className="p-2 text-sm border-r border-gray-300">
                        {new Date(order.createdAt).toLocaleString()}
                      </td>
                      <td className="p-2 border-r border-gray-300">
                        {order.customerName}
                      </td>
                      <td className="p-2 border-r border-gray-300">
                        {order.customerEmail}
                      </td>
                      <td className="p-2 border-r border-gray-300">
                        {order.address}
                      </td>
                      <td className="p-2 w-1/3 border-r border-gray-300">
                        <div className="grid grid-cols-2 gap-2">
                          {order.products.map((product, index) => (
                            <div
                              key={index}
                              className="mb-2 border-b border-gray-200 pb-2"
                            >
                              <strong>{product.name}</strong>
                              <div>Size: {product.size} ml</div>
                              <div>Price: {product.price} $</div>
                              <div>Quantity: {product.quantity}</div>
                            </div>
                          ))}
                        </div>
                        <div className="font-bold text-right mt-2">
                          Total Price:{" "}
                          {order.products.reduce(
                            (sum, p) => sum + p.price * p.quantity,
                            0
                          )}{" "}
                          $
                        </div>
                      </td>
                      <td className="p-2 border-r border-gray-300">
                        {order.fraktjakt.shippingMethod}
                      </td>
                      <td className="p-2 border-r border-gray-300">
                        {order.fraktjakt.shippingCost} EUR
                      </td>
                      <td className="p-2 border-r border-gray-300">
                        {order.fraktjakt.shipmentId || "N/A"}
                      </td>
                      <td className="p-2 flex flex-col items-center justify-center space-y-2">
                        <select
                          className={`p-2 rounded-md font-bold ${getStatusColor(
                            order.status
                          )}`}
                          value={order.status}
                          onChange={(e) =>
                            handleStatusChange(order._id, e.target.value)
                          }
                          style={{ width: "auto" }}
                        >
                          <option value="Processing">Processing</option>
                          <option value="Sent">Sent</option>
                          <option value="Delivered">Delivered</option>
                        </select>
                        <button
                          onClick={() => handleEditOrder(order)}
                          className="bg-yellow-500 text-black p-2 rounded-md hover:bg-yellow-600 transition"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => setConfirmDelete(order)}
                          className="bg-red-500 text-white p-2 rounded-md hover:bg-red-600 transition"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}
          </div>

          {/* Pagination Controls */}
          <div className="pagination-controls flex justify-center mt-4">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="bg-warmSand text-lavaBlack py-2 px-4 mx-1 rounded-lg hover:bg-[#f0d8b5] transition"
            >
              Previous
            </button>

            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageClick(index + 1)}
                className={`py-2 px-4 mx-1 rounded-lg transition ${
                  currentPage === index + 1
                    ? "bg-blue-500 text-white"
                    : "bg-warmSand text-lavaBlack hover:bg-[#f0d8b5]"
                }`}
              >
                {index + 1}
              </button>
            ))}

            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="bg-warmSand text-lavaBlack py-2 px-4 mx-1 rounded-lg hover:bg-[#f0d8b5] transition"
            >
              Next
            </button>
          </div>

          {/* Modal for notifications */}
          {showModal && (
            <div className="fixed top-0 left-0 w-full flex justify-center z-50">
              <div className="p-4 bg-green-500 text-white rounded-md shadow-lg mt-4">
                {modalMessage}
              </div>
            </div>
          )}

          {/* Confirm Delete Modal */}
          {confirmDelete && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
              <div className="bg-white p-8 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold mb-4">Are you sure?</h2>
                <p className="mb-4">
                  Do you really want to delete this order? This process cannot
                  be undone.
                </p>
                <div className="flex justify-end space-x-4">
                  <button
                    onClick={() => setConfirmDelete(null)}
                    className="bg-gray-400 text-white px-4 py-2 rounded-lg hover:bg-gray-500 transition"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleDeleteOrder(confirmDelete._id)}
                    className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Edit Order Modal */}
          {editOrder && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
              <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
                <h2 className="text-2xl font-bold mb-4">Edit Order</h2>
                {/* Form fields for editing */}
                <div className="mb-4">
                  <label className="block font-bold mb-1">Customer Name</label>
                  <input
                    type="text"
                    value={editOrder.customerName}
                    onChange={(e) =>
                      setEditOrder({
                        ...editOrder,
                        customerName: e.target.value,
                      })
                    }
                    className="w-full p-2 border rounded-md"
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-bold mb-1">Customer Email</label>
                  <input
                    type="email"
                    value={editOrder.customerEmail}
                    onChange={(e) =>
                      setEditOrder({
                        ...editOrder,
                        customerEmail: e.target.value,
                      })
                    }
                    className="w-full p-2 border rounded-md"
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-bold mb-1">Address</label>
                  <input
                    type="text"
                    value={editOrder.address}
                    onChange={(e) =>
                      setEditOrder({ ...editOrder, address: e.target.value })
                    }
                    className="w-full p-2 border rounded-md"
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-bold mb-1">Products</label>
                  {editOrder.products.map((product, index) => (
                    <div key={index} className="border-b mb-2 pb-2">
                      <input
                        type="text"
                        value={product.name}
                        readOnly
                        className="w-full p-2 border rounded-md mb-2 bg-gray-100"
                      />
                      <label>Size</label>
                      <input
                        type="text"
                        value={product.size}
                        onChange={(e) => {
                          const updatedProducts = [...editOrder.products];
                          updatedProducts[index].size = e.target.value;
                          setEditOrder({
                            ...editOrder,
                            products: updatedProducts,
                          });
                        }}
                        className="w-full p-2 border rounded-md mb-2"
                      />
                      <label>Quantity</label>
                      <input
                        type="number"
                        value={product.quantity}
                        onChange={(e) => {
                          const updatedProducts = [...editOrder.products];
                          updatedProducts[index].quantity = e.target.value;
                          setEditOrder({
                            ...editOrder,
                            products: updatedProducts,
                          });
                        }}
                        className="w-full p-2 border rounded-md"
                      />
                    </div>
                  ))}
                </div>
                {/* Note field for adding or editing a note */}
                <div className="mb-4">
                  <label className="block font-bold mb-1">Note</label>
                  <textarea
                    value={editOrder.note || ""}
                    onChange={(e) =>
                      setEditOrder({ ...editOrder, note: e.target.value })
                    }
                    className="w-full p-2 border rounded-md"
                    rows="4"
                    placeholder="Enter a note for the order (e.g., special instructions, customer remarks, etc.)"
                  />
                </div>

                <div className="flex justify-end space-x-4">
                  <button
                    onClick={() => setEditOrder(null)}
                    className="bg-gray-400 text-white px-4 py-2 rounded-lg hover:bg-gray-500 transition"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={saveEdits}
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <p className="text-black">No orders found.</p>
      )}
    </div>
  );
};

export default OrderManagement;
