// client/src/api/analytics.js

import axios from 'axios';

const trackEvent = async (eventName, eventData = {}) => {
  try {
    await axios.post('/api/analytics', { eventName, eventData });
  } catch (error) {
    console.error('Error tracking event:', error);
  }
};

export default trackEvent;
