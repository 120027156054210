// client/src/components/LiveVisits.js

import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import getApiUrl from '../utils/apiConfig';

const LiveVisits = () => {
  const [liveVisits, setLiveVisits] = useState(0);
  const apiUrl = getApiUrl();

  useEffect(() => {
    const socket = io(apiUrl, {
      transports: ['websocket', 'polling'], // Allow both transports
      // Remove 'upgrade: false' to enable transport upgrades
    });

    socket.on('connect', () => {
    });

    socket.on('liveVisitsUpdate', (data) => {
      setLiveVisits(data.liveVisits);
    });

    socket.on('disconnect', () => {
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className="text-lg font-bold">
      Current Live Visits: {liveVisits-2}
    </div>
  );
};

export default LiveVisits;
