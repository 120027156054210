import React, { useState, useEffect } from "react";
import "./ClientInfoForm.css";

const ClientInfoForm = ({ billingDetails, setBillingDetails }) => {
  const [errors, setErrors] = useState({});

  const availableCountries = [
    "Austria", "Belgium", "Czechia", "Denmark", "Estonia", "Finland", "France",
    "Germany", "Hungary", "Ireland", "Italy", "Latvia", "Lithuania", "Luxembourg",
    "Netherlands", "Norway", "Poland", "Portugal", "Slovakia", "Slovenia", "Spain",
    "Sweden", "Switzerland", "UK",
  ];

  useEffect(() => {
    const fetchCountryFromIP = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        const userCountry = data.country_name;

        const matchedCountry = availableCountries.includes(userCountry)
          ? userCountry
          : "";

        setBillingDetails((prevDetails) => ({
          ...prevDetails,
          country: matchedCountry,
        }));
      } catch (error) {
        console.error("Error fetching country from IP:", error);
      }
    };

    fetchCountryFromIP();
  }, [setBillingDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails({
      ...billingDetails,
      [name]: value,
    });

    let fieldErrors = { ...errors };
    if (!value) {
      fieldErrors[name] = "This field is required";
    } else if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
      fieldErrors[name] = "Invalid email format";
    } else {
      delete fieldErrors[name];
    }
    setErrors(fieldErrors);
  };

  return (
    <div className="client-info-container">
      <h2 className="text-xl font-bold mb-6">Shipping Information</h2>
      <form className="flex flex-col gap-4">
        <div>
          <label htmlFor="country" className="block text-sm font-semibold mb-2">
            Choose one of the countries we ship to
          </label>
          <select
            name="country"
            id="country"
            value={billingDetails.country}
            onChange={handleInputChange}
            className="input-field"
          >
            <option value="">Select Country</option>
            {availableCountries.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select>
          {errors.country && (
            <span className="text-red-600 text-sm">{errors.country}</span>
          )}
        </div>

        {["name", "email", "address", "city", "postalCode"].map((field, index) => (
          <div key={index}>
            <label
              htmlFor={field}
              className="block text-sm font-semibold mb-2"
            >
              {field === "name"
                ? "Name & Surname"
                : field === "postalCode"
                ? "Postal Code"
                : field.charAt(0).toUpperCase() + field.slice(1)}
            </label>
            <input
              type={field === "email" ? "email" : "text"}
              id={field}
              name={field}
              placeholder={
                field === "name"
                  ? "Name & Surname"
                  : field === "postalCode"
                  ? "Postal Code"
                  : field.charAt(0).toUpperCase() + field.slice(1)
              }
              value={billingDetails[field]}
              onChange={handleInputChange}
              className="input-field"
              style={{ color: "black" }} // Ensures black text color
            />
            {errors[field] && (
              <span className="text-red-600 text-sm">{errors[field]}</span>
            )}
          </div>
        ))}


      </form>
    </div>
  );
};

export default ClientInfoForm;
