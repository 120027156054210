import React, { createContext, useState } from 'react';

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isPrivacyOpen, setPrivacyOpen] = useState(false);
  const [isTermsOpen, setTermsOpen] = useState(false);

  const togglePrivacyModal = () => setPrivacyOpen(!isPrivacyOpen);
  const toggleTermsModal = () => setTermsOpen(!isTermsOpen);

  return (
    <ModalContext.Provider value={{ isPrivacyOpen, isTermsOpen, togglePrivacyModal, toggleTermsModal }}>
      {children}
    </ModalContext.Provider>
  );
};
