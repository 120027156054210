import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Analytics = () => {
    const [summaryData, setSummaryData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Define API URLs for development and production
                const interestApiUrl = process.env.NODE_ENV === 'development'
                    ? 'http://localhost:5000/api/analytics/most-interest'
                    : '/api/analytics/most-interest';
                const summaryApiUrl = process.env.NODE_ENV === 'development'
                    ? 'http://localhost:5000/api/analytics/summary'
                    : '/api/analytics/summary';

                // Fetch both interest data and summary data concurrently
                const [interestResponse, summaryResponse] = await Promise.all([
                    fetch(interestApiUrl, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` },
                    }),
                    fetch(summaryApiUrl, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` },
                    }),
                ]);

                // Check if responses are okay
                if (!interestResponse.ok || !summaryResponse.ok) {
                    throw new Error('Failed to fetch analytics data');
                }

                // Parse JSON responses
                const interestData = await interestResponse.json();
                const summaryData = await summaryResponse.json();


                // Combine data into a single object
                setSummaryData({
                    ...summaryData,
                    mostInterestedProducts: interestData.mostInterestedProducts || []
                });
            } catch (error) {
                console.error('Error fetching analytics data:', error);
            }
        };

        fetchData();
    }, []);

    if (!summaryData) {
        return <div>Loading...</div>;
    }


    return (
        <div className="analytics-page bg-lavaBlack text-silverGray min-h-screen p-8">
            <button
                onClick={() => navigate('/admin/dashboard')}
                className="bg-warmSand text-lavaBlack py-2 px-4 mb-6 rounded-lg hover:bg-[#f0d8b5] transition"
            >
                Back to Dashboard
            </button>

            <h1 className="text-4xl font-bold mb-6 text-center">Analytics Summary</h1>
            <div className="w-full max-w-5xl mx-auto">

                {/* Products with Most Interest Requests */}
                <div className="mb-8">
                    <h2 className="text-2xl font-bold mb-4">Top 20 Products with the Most Interest Requests</h2>
                    <table className="w-full bg-white text-black rounded-lg shadow-md">
                        <thead>
                            <tr className="bg-warmSand">
                                <th className="py-2 px-4 border">Product Name</th>
                                <th className="py-2 px-4 border">Interest Requests</th>
                            </tr>
                        </thead>
                        <tbody>
                            {summaryData?.mostInterestedProducts?.length > 0 ? (
                                summaryData.mostInterestedProducts.map((product, index) => (
                                    <tr key={index}>
                                        <td className="py-2 px-4 border">
                                            {product.name || 'Unnamed Product'}
                                        </td>
                                        <td className="py-2 px-4 border">
                                            {product.interestRequests ?? '0'}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td className="py-2 px-4 border" colSpan="2">No interest requests logged yet.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {/* Top 10 Most Visited Products */}
                <div className="mb-8">
                    <h2 className="text-2xl font-bold mb-4">Top 10 Most Visited Products</h2>
                    <table className="w-full bg-white text-black rounded-lg shadow-md">
                        <thead>
                            <tr className="bg-warmSand">
                                <th className="py-2 px-4 border">Product Name</th>
                                <th className="py-2 px-4 border">Visit Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {summaryData.mostVisitedProducts?.length > 0 ? (
                                summaryData.mostVisitedProducts.map((item, index) => (
                                    <tr key={index}>
                                        <td className="py-2 px-4 border">
                                            {item.product?.name || 'Unknown Product'}
                                        </td>
                                        <td className="py-2 px-4 border">{item.count / 2}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td className="py-2 px-4 border" colSpan="2">No product views tracked yet.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {/* Top 10 Most Visited Routes */}
                <div className="mb-8">
                    <h2 className="text-2xl font-bold mb-4">Top 10 Most Visited Routes</h2>
                    <table className="w-full bg-white text-black rounded-lg shadow-md">
                        <thead>
                            <tr className="bg-warmSand">
                                <th className="py-2 px-4 border">Route</th>
                                <th className="py-2 px-4 border">Visit Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {summaryData.mostVisitedRoutes?.length > 0 ? (
                                summaryData.mostVisitedRoutes.map((route, index) => (
                                    <tr key={index}>
                                        <td className="py-2 px-4 border">{route._id}</td>
                                        <td className="py-2 px-4 border">{route.count}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td className="py-2 px-4 border" colSpan="2">No routes tracked yet.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Analytics;
