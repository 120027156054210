// client/src/AppContent.js

import React, { useEffect, useState, useContext } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSession } from "./context/SessionContext";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Products from "./pages/Products";
import About from "./pages/About";
import Contact from "./pages/Contact";
import StickyCylinders from "./components/StickyCylinders";
import ConsentPopup from "./components/ConsentPopup";
import AdminLogin from "./components/AdminLogin";
import AdminDashboard from "./components/AdminDashboard";
import ProductDetails from "./pages/ProductDetails";
import PaymentForm from "./components/PaymentForm";
import OrderManagement from "./components/OrderManagement";
import ProtectedRoute from "./components/ProtectedRoute";
import AnalyticsProvider from "./components/AnalyticsProvider";
import Analytics from "./pages/Analytics";
import AnalyticsSummary from "./components/AnalyticsSummary";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import Modal from "./components/Modal";
import { ModalContext } from "./context/ModalContext";
import io from "socket.io-client";
import getApiUrl from "./utils/apiConfig";
import RouteTracker from "./components/RouteTracker";
import trackEvent from "./api/analytics"; // Import trackEvent function
import ProductOrdering from "./components/ProductOrdering";

function AppContent() {
  const { clientSessionStarted, loading, setCylinders } = useSession();
  const [showComponents, setShowComponents] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [notification, setNotification] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const adminRoutes = ["/admin/dashboard", "/admin/analytics", "/admin/orders"];
  const isAdminRoute = adminRoutes.includes(location.pathname);

  // Get modal state and functions from context
  const { isPrivacyOpen, isTermsOpen, togglePrivacyModal, toggleTermsModal } =
    useContext(ModalContext);

  useEffect(() => {
    localStorage.setItem("lastRoute", location.pathname);
  }, [location]);

  useEffect(() => {
    // Check if the user is visiting for the first time in this session
    if (!sessionStorage.getItem("siteVisited")) {
      trackEvent("site_visit", {});
      sessionStorage.setItem("siteVisited", "true");
    }
  }, []);

  // Establish Socket.IO connection for clients
  useEffect(() => {
    const apiUrl = getApiUrl();
    const socket = io(apiUrl, {
      transports: ["websocket", "polling"],
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!loading && clientSessionStarted) {
      setShowComponents(true);
      if (location.pathname === "/products") {
        const consentGiven = localStorage.getItem("consentGiven");
        if (!consentGiven) {
          setShowPopup(true);
        }
      }
    } else {
      setShowComponents(false);
    }
  }, [clientSessionStarted, loading, location.pathname]);

  const showNotification = (message, closeModal) => {
    setNotification(message);
    setTimeout(() => {
      setNotification("");
      closeModal();
      setCylinders(Array(3).fill(null));
      navigate("/products");
    }, 3000);
  };

  return (
    <AnalyticsProvider>
      <div className="App min-h-screen flex flex-col justify-between">
        {showComponents && <Header />}
        <RouteTracker />

        {isAdminRoute && (
          <div className="w-full bg-lavaBlack text-silverGray">
            <AnalyticsSummary />
          </div>
        )}

        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:id" element={<ProductDetails />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/admin" element={<AdminLogin />} />
            <Route
              path="/admin/dashboard"
              element={<ProtectedRoute element={<AdminDashboard />} />}
            />
            <Route
              path="/admin/orders"
              element={<ProtectedRoute element={<OrderManagement />} />}
            />
            <Route
              path="/admin/analytics"
              element={<ProtectedRoute element={<Analytics />} />}
            />
            <Route
              path="/checkout"
              element={
                <PaymentForm
                  amount={1000}
                  showNotification={showNotification}
                />
              }
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route
              path="/admin/product-ordering"
              element={<ProtectedRoute element={<ProductOrdering />} />}
            />
          </Routes>
        </main>

        {showComponents && (
          <StickyCylinders showNotification={showNotification} />
        )}

        {notification && (
          <div className="fixed top-0 left-0 w-full flex justify-center z-1000">
            <div className="p-4 bg-green-500 text-white rounded-md shadow-lg mt-4">
              {notification}
            </div>
          </div>
        )}

        {/* Modals */}
        <Modal
          title="Privacy Policy"
          content={<PrivacyPolicy />}
          isOpen={isPrivacyOpen}
          onClose={togglePrivacyModal}
        />
        <Modal
          title="Terms and Conditions"
          content={<TermsAndConditions />}
          isOpen={isTermsOpen}
          onClose={toggleTermsModal}
        />

        <Footer />
        {showPopup && <ConsentPopup />}
      </div>
    </AnalyticsProvider>
  );
}

export default AppContent;
