// Contact.js
import React, { useState } from 'react';
import OrderStatus from '../components/OrderStatus';
import { useGoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const ContactForm = () => {
  const [activeForm, setActiveForm] = useState('contact');
  const [captchaValue, setCaptchaValue] = useState(null);
  const [showModal, setShowModal] = useState(false); // For displaying notification
  const [modalMessage, setModalMessage] = useState(''); // Notification message
  const [modalSuccess, setModalSuccess] = useState(true); // Success or Error modal styling
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleFormSwitch = (form) => {
    setActiveForm(form);
  };

  // Function to run reCAPTCHA and get token
  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('contactForm');
    setCaptchaValue(token);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!captchaValue) {
      alert('reCAPTCHA verification failed.');
      return;
    }

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      message: event.target.message.value,
      'g-recaptcha-response': captchaValue,
    };

    try {
      const response = await fetch('/api/send-contact-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setModalMessage('Message sent! Thanks, we will get back to you.');
        setModalSuccess(true); // Show success modal
        event.target.reset(); // Clear form upon success
      } else {
        setModalMessage('Something went wrong. Please contact support@ttscent.com.');
        setModalSuccess(false); // Show error modal
      }

      setShowModal(true);
      setTimeout(() => setShowModal(false), 3000); // Hide modal after 3 seconds

    } catch (error) {
      console.error('Error:', error);
      setModalMessage('Something went wrong. Please contact support@ttscent.com.');
      setModalSuccess(false); // Show error modal
      setShowModal(true);
      setTimeout(() => setShowModal(false), 3000);
    }
  };

  return (
    <div className="contact-page bg-lavaBlack text-silverGray min-h-screen p-8 flex flex-col items-center">
      {showModal && (
        <div className="fixed top-[40px] left-0 w-full flex justify-center z-50">
          <div
            className={`p-3 rounded-md shadow-lg mt-4 ${
              modalSuccess ? 'bg-green-500 text-white' : 'bg-red-500 text-white'
            }`}
          >
            <p>{modalMessage}</p>
          </div>
        </div>
      )}

      <div className="w-full max-w-lg mt-8">
        <div className="flex justify-center mb-8">
          <button
            className={`px-4 py-2 mx-2 rounded-lg focus:outline-none ${
              activeForm === 'contact'
                ? 'bg-warmSand text-lavaBlack'
                : 'bg-lavaBlack text-warmSand border border-warmSand'
            }`}
            onClick={() => handleFormSwitch('contact')}
          >
            Contact Us
          </button>
          <button
            className={`px-4 py-2 mx-2 rounded-lg focus:outline-none ${
              activeForm === 'track'
                ? 'bg-warmSand text-lavaBlack'
                : 'bg-lavaBlack text-warmSand border border-warmSand'
            }`}
            onClick={() => handleFormSwitch('track')}
          >
            Track Order
          </button>
        </div>

        {activeForm === 'contact' && (
          <div>
            <h1 className="text-4xl font-bold mb-6 text-center">Contact Us</h1>

            <form
              className="contact-form space-y-4"
              onSubmit={handleSubmit}
              onMouseEnter={handleReCaptchaVerify}
            >
              <div className="form-group">
                <label className="block mb-2 text-silverGray">Your Name</label>
                <input
                  type="text"
                  name="name"
                  className="w-full p-2 bg-lavaBlack text-silverGray border border-silverGray rounded-lg focus:border-warmSand"
                  required
                />
              </div>

              <div className="form-group">
                <label className="block mb-2 text-silverGray">Your Email</label>
                <input
                  type="email"
                  name="email"
                  className="w-full p-2 bg-lavaBlack text-silverGray border border-silverGray rounded-lg focus:border-warmSand"
                  required
                />
              </div>

              <div className="form-group">
                <label className="block mb-2 text-silverGray">Your Message</label>
                <textarea
                  name="message"
                  className="w-full p-2 bg-lavaBlack text-silverGray border border-silverGray rounded-lg focus:border-warmSand"
                  rows="6"
                  required
                ></textarea>
              </div>

              <button
                type="submit"
                className="bg-warmSand text-lavaBlack py-2 px-4 rounded-lg hover:bg-[#f0d8b5] transition"
              >
                Send Message
              </button>
            </form>
          </div>
        )}

        {activeForm === 'track' && (
          <div>
            <OrderStatus />
          </div>
        )}
      </div>
    </div>
  );
};

const Contact = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LcozmkqAAAAAIvqFG8PA81s0iWR2EUTY72EECRQ" // Replace with your actual site key
      scriptProps={{
        async: true,
        defer: true,
        appendTo: 'body',
        params: {
          badge: 'bottomright', // Position of the reCAPTCHA badge
        },
      }}
    >
      <ContactForm />
    </GoogleReCaptchaProvider>
  );
};

export default Contact;
