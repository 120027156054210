import React from 'react';
import { useContext } from 'react';
import { ModalContext } from '../context/ModalContext';

const About = () => {
  const { togglePrivacyModal, toggleTermsModal } = useContext(ModalContext);

  return (
    <div className="about-page bg-lavaBlack text-silverGray min-h-screen p-8 flex flex-col items-center">
      <div className="w-full max-w-2xl mt-12 text-center"> {/* Limit the width and center align */}
        <h1 className="text-4xl font-bold mb-6 text-warmSand">About Us</h1>
        <p className="text-lg leading-relaxed mb-8">
          At ttScent, we are passionate about bringing you the finest, handpicked fragrances from top brands around the world.
          Whether you are looking for something luxurious or a scent that complements your unique personality, we have something tailored to your needs.
        </p>
        <p className="text-lg leading-relaxed mb-8">
          Our mission is to offer a personalized perfume experience, allowing you to discover your signature scent through a curated collection
          of both classic and contemporary fragrances. Enjoy luxury in every bottle, delivered straight to your door.
        </p>
        <p className="text-lg leading-relaxed mb-8">
          We provide authentic, high-quality sample-sized perfumes intended for testing purposes, to help you discover your signature scent. Our samples allow you to experience luxury fragrances without committing to full-sized products.
        </p>

        <p className="text-lg leading-relaxed mb-8">
          Explore our wide variety of scents and indulge in an olfactory journey with ttScent.
        </p>
        <p>
          For more information, please read our{' '}
          <span className="text-blue-500 cursor-pointer" onClick={toggleTermsModal}>
            Terms and Conditions
          </span>{' '}
          and{' '}
          <span className="text-blue-500 cursor-pointer" onClick={togglePrivacyModal}>
            Privacy Policy
          </span>.
        </p>
      </div>
    </div>
  );
}

export default About;
