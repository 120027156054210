import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const headerRef = useRef(null); // Reference for the header element

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  // Close the menu if clicking outside of the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Sticky header effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        headerRef.current.classList.add('stickyHeader');
      } else {
        headerRef.current.classList.remove('stickyHeader');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className="bg-lavaBlack p-4 flex justify-between items-center" ref={headerRef}>
      <Link to="/" className="text-xl font-bold text-silverGray">ttScent</Link>
      
      {/* Hamburger icon for mobile/tablet view */}
      <div className="lg:hidden" onClick={toggleMenu}>
        {menuOpen ? <FaTimes className="text-silverGray text-2xl cursor-pointer" /> : <FaBars className="text-silverGray text-2xl cursor-pointer" />}
      </div>
      
      {/* Navigation links for larger screens */}
      <nav className="hidden lg:flex space-x-4">
        <Link to="/" className="text-silverGray hover:text-warmSand">Home</Link>
        <Link to="/products" className="text-silverGray hover:text-warmSand">Products</Link>
        <Link to="/about" className="text-silverGray hover:text-warmSand">About Us</Link>
        <Link to="/contact" className="text-silverGray hover:text-warmSand">Contact/Track</Link>
      </nav>

      {/* Mobile menu */}
      {menuOpen && (
        <nav className={`mobileMenu ${menuOpen ? 'open' : ''}`} ref={menuRef}> {/* Attach ref to menu container */}
          <Link to="/" onClick={closeMenu}>Home</Link>
          <Link to="/products" onClick={closeMenu}>Products</Link>
          <Link to="/about" onClick={closeMenu}>About Us</Link>
          <Link to="/contact" onClick={closeMenu}>Contact/Track</Link>
        </nav>
      )}
    </header>
  );
};

export default Header;
