import React, { useEffect, useState } from 'react';
import AnimatedSprayButton from '../components/AnimatedSprayButton';
import { useSession } from '../context/SessionContext'; 
import trackEvent from '../api/analytics'; 
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const { startSession } = useSession(); 
  const [spraying, setSpraying] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const isVisitTracked = sessionStorage.getItem('isVisitTracked');

    if (!isVisitTracked) {
      trackEvent('site_visit', { url: window.location.href });
      sessionStorage.setItem('isVisitTracked', 'true'); 
    }
  }, []);

  const handleStartSession = () => {
    if (!spraying) {
      setSpraying(true);
      startSession(); 
      setTimeout(() => {
        navigate('/products'); // Navigate after animation
      }, 2000); // Adjust delay to match the animation duration
    }
  };

  return (
    <div
      className="home bg-lavaBlack text-silverGray min-h-screen flex flex-col items-center px-4 lg:px-0"
      onClick={handleStartSession}
    >
      <section className="hero bg-cover bg-center h-screen flex items-center justify-center relative w-full">
        <div className="absolute inset-0 bg-lavaBlack opacity-90"></div>
  
        <div className="relative z-10 text-center p-8 translate-y-[-80px] md:translate-y-0">
          <h1 className="text-3xl lg:text-5xl font-bold text-silverGray mb-4">ttScent</h1>
          <h1 className="text-2xl lg:text-3xl font-bold text-silverGray mb-4">Discover Your Signature Scent</h1>
          <p className="text-md lg:text-lg text-silverGray mb-2">
            Handpicked fragrances from top brands, delivered to your doorstep.
          </p>
          <p className="text-md lg:text-lg text-silverGray mb-12">Spray to begin.</p>
  
          <AnimatedSprayButton spraying={spraying} />
        </div>
      </section>
    </div>
  );
};

export default Home;
