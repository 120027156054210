// client/src/components/AdminLogin.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../context/SessionContext';

const AdminLogin = () => {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login } = useSession(); // Use the login function from context

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      localStorage.removeItem('adminToken');
      localStorage.removeItem('adminTokenExpiry');

      const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password }),
      });

      if (response.ok) {
        const data = await response.json();
        login(data.token, { role: 'admin' }); // Set session with token
        navigate('/admin/dashboard');
      } else if (response.status === 429) {
        const errorData = await response.json();
        alert(`Login failed: ${errorData.message}`);
      } else {
        alert('Login failed: Please check your password.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('An error occurred while trying to log in.');
    }
  };

  return (
    <div className="admin-login-page bg-lavaBlack text-silverGray min-h-screen p-8 flex flex-col items-center">
      <div className="w-full max-w-lg mt-8">
        <h1 className="text-4xl font-bold mb-6 text-center">Admin Login</h1>
        <form className="login-form space-y-4" onSubmit={handleLogin}>
          <div className="form-group">
            <label className="block mb-2 text-silverGray">Enter Admin Password</label>
            <input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 bg-lavaBlack text-silverGray border border-silverGray rounded-lg focus:border-warmSand" 
              required
            />
          </div>
          <button 
            type="submit" 
            className="bg-warmSand text-lavaBlack py-2 px-4 rounded-lg hover:bg-[#f0d8b5] transition">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
