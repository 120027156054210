import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useSession } from "../context/SessionContext";
import useAnalytics from '../hooks/useAnalytics';

const ProductDetails = () => {
  const { id } = useParams(); // Get product ID from URL
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // For displaying notification
  const [modalMessage, setModalMessage] = useState(""); // Notification message
  const { addCylinder } = useSession(); // Access the addCylinder function
  const { logEvent } = useAnalytics(); // Use the logEvent function from useAnalytics
  const [selectedSize, setSelectedSize] = useState(null); // Re-add this line
  const { cylinders } = useSession();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`/api/products/${id}`);
        if (!response.ok) {
          throw new Error("Product not found");
        }
        const data = await response.json();
        setProduct(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  useEffect(() => {
    // Log product view when the component mounts or when the product ID changes
    logEvent('product_view', { productId: id });
  }, [id]);
  
  const handleSelectSize = (size) => {
    if (product) {
      const totalAddedQuantity = cylinders
        .filter(c => c && c._id === product._id)
        .reduce((total, c) => total + (c.quantity || 1) * c.selectedSize.size, 0);

      const availableStock = product.stock - totalAddedQuantity;

      // Check if the total size including the newly added size exceeds the stock
      if (size.size > availableStock) {
        setModalMessage(`Sorry, only ${availableStock}ml left for ${product.model}.`); // Show error in modal
        setShowModal(true);
        setTimeout(() => setShowModal(false), 3000);
        return;
      }

      // Add the selected size if the stock is sufficient
      addCylinder({ ...product, selectedSize: size });
      setModalMessage(
        `${formatBrandOrModelName(product.brand)}, ${formatBrandOrModelName(product.model)} (${size.size}ml) added to cart.`
      );
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 3000);
    }
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Utility function to format brand or model names
  const formatBrandOrModelName = (name) => {
    return name.replace(/_/g, " "); // Replace underscores with spaces
  };


  const handleRequestStock = async (productId) => {
    try {
      const apiUrl = process.env.NODE_ENV === 'development'
        ? `http://localhost:5000/api/products/${productId}/request-stock`
        : `/api/products/${productId}/request-stock`;

      const response = await fetch(apiUrl, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Failed to log interest');
      }

      const updatedProductResponse = await fetch(`/api/products/${productId}`);
      const updatedProduct = await updatedProductResponse.json();

      setProduct(updatedProduct);
      setModalMessage('Your interest has been logged. Thank you!');
      setShowModal(true);
      setTimeout(() => setShowModal(false), 3000);
    } catch (error) {
      console.error('Error logging interest:', error);
    }
  };



  return (
    <div className="product-details bg-lavaBlack text-silverGray py-12 min-h-screen relative">
      {showModal && (
        <div className="fixed top-[40px] left-0 w-full flex justify-center z-50">
          <div
            className={`p-3 rounded-md shadow-lg mt-4 ${modalMessage.includes("added") || modalMessage.includes("logged")
              ? "bg-green-500 text-white"
              : "bg-red-500 text-white"
              }`}
          >
            <p>{modalMessage}</p>
          </div>
        </div>
      )}




      <div className="max-w-3xl mx-auto">
        {/* Back Button */}
        <div className="flex justify-start mb-4">
          <Link
            to="/products"
            className="text-warmSand p-3 rounded-lg hover:bg-[#3b3b3b] transition"
          >
            ← Back to Products
          </Link>
        </div>

        {/* Product Name */}
        <h1 className="text-4xl font-bold mb-6 text-center">
          {formatBrandOrModelName(product.brand)}, {formatBrandOrModelName(product.model)}
        </h1>

        {/* Image Section */}
        <div className="w-full max-w-md mx-auto mb-8">
          {product.images && product.images.length > 0 && product.brand && product.model ? (
            <img
              src={`/uploads/${product.gender || "unknown"}/${product.brand
                .replace(/\s+/g, "_")
                .toLowerCase()}/${product.model
                  .replace(/\s+/g, "_")
                  .toLowerCase()}/${product.images[0]}`}
              alt={`${formatBrandOrModelName(product.brand)} ${formatBrandOrModelName(product.model)}`}
              className="w-full h-96 object-contain rounded-lg bg-white" // Use object-contain to preserve aspect ratio and add background
            />
          ) : (
            <p>No Image Available</p>
          )}
        </div>

        {/* Product Information */}
        <div className="text-center space-y-4">
          <p className="text-lg">
            <span className="font-bold">Brand:</span> {formatBrandOrModelName(product.brand)}
          </p>

          {/* Display the Description */}
          <p>
            <span className="font-bold">Description:</span>
          </p>
          <p className="text-lg">{product.description}</p>

          {/* Season and Gender in the same row */}
          <div className="flex justify-center items-center space-x-4 max-w-sm mx-auto">
            <p className="text-lg text-center">
              <span className="font-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Season:</span> {product.season}
            </p>
            <div className="w-[2px] h-6 bg-silverGray"></div>{" "}
            {/* Vertical Line */}
            <p className="text-lg text-center">
              <span className="font-bold">Gender:</span> {product.gender === "manly" ? "Masculine" : product.gender}
              {/* Display 'Masculine' for manly */}
            </p>
          </div>

          {/* Price Section */}
          {product.stock <= 25 && product.stock > 0 && (
            <p className="low-stock-warning text-red-600">Hurry! Only {product.stock}ml left in stock!</p>
          )}
          {product.stock === 0 && (
            <div className="flex justify-center items-center space-x-2">
              <p className="text-lg font-bold text-red-600">Out of Stock</p>
              <button
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-3 rounded-md"
                onClick={() => handleRequestStock(product._id)}  // Ensure this triggers the function
              >
                Request
              </button>
            </div>
          )}




          <div className="text-lg">
            <p className="text-sm text-lavaBlack mt-2">Choose a size to add:</p>
            <ul className="mt-2 flex justify-center space-x-4">
              {product.sizes.map((size, index) => {
                const totalAddedQuantity = cylinders
                  .filter(c => c && c._id === product._id)
                  .reduce((total, c) => total + (c.quantity || 1) * c.selectedSize.size, 0);

                const availableStock = product.stock - totalAddedQuantity;
                const isOutOfStock = size.size > availableStock; // Check if size exceeds available stock

                return (
                  <li
                    key={index}
                    className={`px-6 py-2 border rounded-lg cursor-pointer ${isOutOfStock ? "bg-gray-400 cursor-not-allowed" : "bg-lavaBlack text-warmSand"}`}
                    onClick={() => {
                      if (!isOutOfStock) handleSelectSize(size);
                    }}
                  >
                    {size.size}ml - €{size.price}
                  </li>
                );
              })}
            </ul>

          </div>



        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
