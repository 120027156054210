// client/src/utils/apiConfig.js

const getApiUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:5000";
  } else {
    return window.location.origin; // Use the current origin in production
  }
};

export default getApiUrl;
