import React, { useState, useEffect, useContext } from 'react';
import { ModalContext } from '../context/ModalContext';
import { useSession } from '../context/SessionContext';

const ConsentPopup = () => {
  const [showPopup, setShowPopup] = useState(false); // Popup hidden initially
  const { setConsentGiven } = useSession();
  const { togglePrivacyModal } = useContext(ModalContext);

  // Check if consent is already given in localStorage
  useEffect(() => {
    const consentGiven = localStorage.getItem('consentGiven');

    // If consent is not found in localStorage, show the popup
    if (!consentGiven) {
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 3000); // Show popup after 3 seconds

      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, []);

  const handleConsent = () => {
    setConsentGiven(true); // User gave consent in session state
    localStorage.setItem('consentGiven', 'true'); // Save consent to localStorage
    setShowPopup(false);    // Hide the popup
  };

  const handleDecline = () => {
    setConsentGiven(false); // User declined consent in session state
    localStorage.setItem('consentGiven', 'false'); // Save decline to localStorage
    setShowPopup(false);    // Hide the popup
  };

  if (!showPopup) return null;

  return (
    <div className="fixed bottom-0 left-0 w-full bg-[#282424] text-[#d3c0b4] z-50 p-3 md:p-4 flex items-center justify-center">
      <div className="w-full max-w-screen-lg mx-auto flex flex-col md:flex-row justify-between items-center p-4 md:p-6 bg-[#403b3b] rounded-lg shadow-lg">
        <div className="text-center md:text-left mb-4 md:mb-0">
          <h2 className="text-lg md:text-xl font-bold text-[#f5e4d7]">We Value Your Privacy</h2>
          <p className="text-sm">
            We would like to store information locally (e.g., in cookies or local storage) for a better user experience and to gather statistics. By agreeing, you consent to our use of cookies and the collection of data as described in our{' '}
            <span className="text-blue-500 cursor-pointer" onClick={togglePrivacyModal}>
              Privacy Policy
            </span>.
          </p>


        </div>
        <div className="flex flex-col space-y-2 md:space-y-0 md:space-x-4 md:flex-row">
          <button
            className="bg-[#4CAF50] text-white p-2 px-4 rounded-md hover:bg-[#45a047]"
            onClick={handleConsent}
          >
            Agree
          </button>
          <button
            className="bg-[#f44336] text-white p-2 px-4 rounded-md hover:bg-[#e53935]"
            onClick={handleDecline}
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConsentPopup;
