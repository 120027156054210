import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="custom-modal-content-container">
      <p className="custom-modal-effective-date">Effective date: 15 October 2024</p>

      <h2 className="custom-modal-subheader">1. Introduction</h2>
      <p className="custom-modal-paragraph">
        Welcome to ttScent. We are committed to protecting your personal data in accordance with GDPR and other regulations.
      </p>

      <h2 className="custom-modal-subheader">2. Data We Collect</h2>
      <p className="custom-modal-paragraph">
        We collect the following information: name, email, delivery address, and other information necessary for the order process. All data personal user data is encrypted.
      </p>

      <h2 className="custom-modal-subheader">3. How We Use Your Data</h2>
      <p className="custom-modal-paragraph">
        Your data is used for processing orders, contacting you, and improving your experience.
      </p>

      <h2 className="custom-modal-subheader">4. Sharing Your Data</h2>
      <p className="custom-modal-paragraph">
        We only share your data with trusted third-party services, such as Stripe for payment processing and Mailgun for order confirmations.
      </p>

      <h2 className="custom-modal-subheader">5. User Rights</h2>
      <p className="custom-modal-paragraph">
        You have the right to access, rectify, or delete your personal data.
      </p>

      <h2 className="custom-modal-subheader">6. Data Retention</h2>
      <p className="custom-modal-paragraph">
        We retain your data for as long as necessary to fulfill your order and legal obligations.
      </p>

      <h2 className="custom-modal-subheader">7. Contact Us</h2>
      <p className="custom-modal-paragraph">
        If you have any questions, contact us at suppor@ttscent.com
      </p>
    </div>
  );
};

export default PrivacyPolicy;
