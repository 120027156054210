// client/src/api/api.js

import axios from 'axios';

// Base API instance
const api = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json'
  }
});

export const calculateShippingCost = async (shippingDetails) => {
  try {
    const response = await axios.post('/api/shipping/calculate-shipping', shippingDetails);
    return response.data.shippingOptions;
  } catch (error) {
    console.error("Failed to fetch shipping options", error);
    throw error;
  }
};

export default api;
