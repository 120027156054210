// client/src/components/RouteTracker.js

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import trackEvent from '../api/analytics';

const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Log 'route_visit' event whenever the route changes
    trackEvent('route_visit', { route: location.pathname });
  }, [location]);

  return null; // This component doesn't render anything
};

export default RouteTracker;
