import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

// Optional: You can create a separate CSS file for styles
const styles = {
  container: {
    backgroundColor: '#1e1e1e', // LavaBlack matching the navbar
    color: 'white',
    minHeight: '100vh',
    padding: '20px',
    boxSizing: 'border-box',
  },
  orderingBox: {
    color: 'white',
    padding: '20px',
    borderRadius: '10px',
    maxWidth: '800px',
    margin: '0 auto',
    backgroundColor: '#3b3b3b',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  },
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #ccc',
    padding: '15px',
    marginBottom: '10px',
    background: '#4b4b4b',
    borderRadius: '5px',
  },
  image: {
    width: '50px',
    height: '50px',
    objectFit: 'cover',
    marginRight: '10px',
    borderRadius: '5px',
  },
  button: {
    background: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    cursor: 'pointer',
    flexShrink: 0,
  },
  buttonDisabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  indexBadge: {
    background: '#fff',
    color: '#3b3b3b',
    borderRadius: '50%',
    padding: '5px 10px',
    fontWeight: 'bold',
    flexShrink: 0,
  },
  dragHandle: {
    cursor: 'grab',
    marginLeft: '10px',
    fontSize: '20px',
    flexShrink: 0,
    color: 'white',
  },
};

const ProductOrdering = () => {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Hook for navigation

  // Fetch products on mount
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('/api/products');
        if (!response.ok) {
          throw new Error('Failed to fetch products.');
        }
        const data = await response.json();
        setProducts(
          data.sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0))
        );
      } catch (err) {
        console.error('Error fetching products:', err);
        setError('Failed to load products.');
      }
    };

    fetchProducts();
  }, []);

  // Function to update the order in the backend
  const updateOrder = useCallback(async (updatedOrder) => {
    try {
      const response = await fetch('/api/products/update-order', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ productOrder: updatedOrder }),
      });
      if (!response.ok) {
        throw new Error('Failed to update product order.');
      }
    } catch (err) {
      console.error('Error updating product order:', err);
      setError('Failed to update product order. Please try again.');
    }
  }, []);

  // Move product up or down via buttons
  const moveProduct = useCallback(
    async (index, direction) => {
      const newIndex = index + direction;

      if (newIndex < 0 || newIndex >= products.length) return;

      const updatedProducts = [...products];
      const [movedProduct] = updatedProducts.splice(index, 1);
      updatedProducts.splice(newIndex, 0, movedProduct);

      setProducts(updatedProducts);
      await updateOrder(updatedProducts.map((product) => product._id));
    },
    [products, updateOrder]
  );

  // Handle drag end
  const handleDragEnd = async (result) => {
    setError(null); // Reset error state
    if (!result.destination) return;

    const reorderedProducts = Array.from(products);
    const [moved] = reorderedProducts.splice(result.source.index, 1);
    reorderedProducts.splice(result.destination.index, 0, moved);

    setProducts(reorderedProducts);
    await updateOrder(reorderedProducts.map((product) => product._id));
  };

  // Format image path
  const formatImagePath = (product) => {
    if (
      !product.gender ||
      !product.brand ||
      !product.model ||
      !product.images ||
      !product.images[0]
    ) {
      return ''; // Return an empty string if any necessary property is missing
    }
    return `/uploads/${product.gender
      .toLowerCase()
      .replace(/\s+/g, '_')}/${product.brand
      .replace(/\s+/g, '_')
      .toLowerCase()}/${product.model
      .replace(/\s+/g, '_')
      .toLowerCase()}/${product.images[0]}`;
  };

  return (
    <div style={styles.container}>
      {/* Back to Dashboard Button */}
      <button
        onClick={() => navigate('/admin/dashboard')}
        className="bg-warmSand text-lavaBlack py-2 px-4 ml-4 rounded-lg hover:bg-[#f0d8b5] transition"
      >
        Back to Dashboard
      </button>

      <div style={styles.orderingBox}>
        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>
          Reorder Products
        </h1>
        {error && (
          <div
            style={{
              marginBottom: '20px',
              padding: '10px',
              backgroundColor: '#ff4d4f',
              color: 'white',
              borderRadius: '5px',
              textAlign: 'center',
            }}
          >
            {error}
          </div>
        )}
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="products">
            {(provided, snapshot) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  ...styles.list,
                  background: snapshot.isDraggingOver ? '#555' : '#3b3b3b',
                  padding: '10px',
                  borderRadius: '5px',
                  minHeight: '100px',
                }}
              >
                {products.map((product, index) => (
                  <Draggable key={product._id} draggableId={product._id} index={index}>
                    {(provided, snapshot) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                          ...styles.listItem,
                          ...provided.draggableProps.style,
                          boxShadow: snapshot.isDragging
                            ? '0 0 .4rem #666'
                            : '0px 4px 8px rgba(0, 0, 0, 0.2)',
                          background: snapshot.isDragging ? '#555' : '#4b4b4b',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={formatImagePath(product)}
                            alt={`${product.brand} ${product.model}`}
                            style={styles.image}
                          />
                          <span>{product.name}</span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            gap: '10px',
                          }}
                        >
                          <button
                            onClick={() => moveProduct(index, -1)}
                            disabled={index === 0}
                            style={{
                              ...styles.button,
                              ...(index === 0 && styles.buttonDisabled),
                            }}
                          >
                            ↑
                          </button>
                          <button
                            onClick={() => moveProduct(index, 1)}
                            disabled={index === products.length - 1}
                            style={{
                              ...styles.button,
                              ...(index === products.length - 1 && styles.buttonDisabled),
                            }}
                          >
                            ↓
                          </button>
                          <span style={styles.indexBadge}>{index + 1}</span>
                          <span
                            {...provided.dragHandleProps}
                            style={styles.dragHandle}
                            title="Drag to reorder"
                          >
                            ☰
                          </span>
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default ProductOrdering;
