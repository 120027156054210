// client/src/components/StickyCylinders.js

import React, { useState, useEffect, useContext } from "react";
import { useSession } from "../context/SessionContext";
import { useLocation } from "react-router-dom";
import BottleWithPerfume from "./BottleWithPerfume";
import PaymentForm from "./PaymentForm";
import ClientInfoForm from "./ClientInfoForm";
import "./StickyCylinders.css";
import { ModalContext } from "../context/ModalContext";
import { calculateShippingCost } from "../api/api";

const StickyCylinders = ({ showNotification, navigate }) => {
  const { clientSessionStarted, cylinders, setCylinders } = useSession();
  const [showModal, setShowModal] = useState(false);
  const [checkoutStep, setCheckoutStep] = useState(1);
  const [quantities, setQuantities] = useState([]);
  const [billingDetails, setBillingDetails] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    postalCode: "",
    country: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const [paymentStatusMessage, setPaymentStatusMessage] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(""); // 'success' or 'error'
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [shippingOptions, setShippingOptions] = useState([]);
  const [selectedShippingOption, setSelectedShippingOption] = useState(null);
  const [isLoadingShippingOptions, setIsLoadingShippingOptions] = useState(false);

  // For terms modal
  const { togglePrivacyModal, toggleTermsModal } = useContext(ModalContext);

  useEffect(() => {
    // Filter out null or undefined cylinders
    const validCylinders = cylinders.filter((cylinder) => cylinder != null);
    if (validCylinders.length > 0) {
      setQuantities(
        validCylinders.map((cylinder) => {
          const qty = parseInt(cylinder.quantity, 10);
          return isNaN(qty) || qty <= 0 ? 1 : qty;
        })
      );
    } else {
      setQuantities([]); // Reset quantities when no cylinders exist
    }
  }, [cylinders]);

  useEffect(() => {
    // Prevent background scrolling when modal is open
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    // Clean up when component unmounts
    return () => {
      document.body.style.overflow = "";
    };
  }, [showModal]);

  // Handle the modal collapse and clearing after payment success
  useEffect(() => {
    if (paymentStatus === "success") {
      // Show the success message
      setShowSuccessMessage(true);

      // Collapse the modal after 2 seconds
      const collapseTimer = setTimeout(() => {
        setShowModal(false);

        // Clear the cart items and quantities
        setCylinders([]);
        setQuantities([]);

        // Clear local storage and session storage data
        localStorage.removeItem("cart");
        sessionStorage.removeItem("cart");

        // Reset checkout step for future orders
        setCheckoutStep(1);
        // Do not reset paymentStatus or paymentStatusMessage here
      }, 2000);

      // Hide the success message after 5 seconds
      const successMessageTimer = setTimeout(() => {
        setShowSuccessMessage(false);
        setPaymentStatus("");
        setPaymentStatusMessage("");
      }, 5000);

      return () => {
        clearTimeout(collapseTimer);
        clearTimeout(successMessageTimer);
      };
    }
  }, [paymentStatus]);

  if (location.pathname.includes("/admin")) return null;
  if (!clientSessionStarted) return null;

  const bottleColors = [
    "#FFCC99",
    "#FFC1CC",
    "#FFD700",
    "#B5651D",
    "#DAA520",
    "#D2B48C",
  ];
  const fillLevel = 80;

  const totalItems = quantities.reduce((total, qty) => total + qty, 0);

  const totalPrice = cylinders.reduce((total, item, index) => {
    if (item && quantities[index]) {
      const price = parseFloat(item.selectedSize.price);
      const quantity = parseInt(quantities[index], 10);
      if (isNaN(price) || isNaN(quantity)) {
        return total; // Skip this item if price or quantity is invalid
      }
      return total + price * quantity;
    }
    return total;
  }, 0);

  // Calculate total weight for all items in the cart
  const totalWeight = cylinders.reduce((total, item, index) => {
    const weightPerItem = item
      ? (item.selectedSize.size + 50) * quantities[index]
      : 0; // Add bottle/container weight (e.g., 50g)
    return total + weightPerItem;
  }, 0);

  const handleQuantityChange = (index, value) => {
    let newQuantity = parseInt(value, 10);
    if (isNaN(newQuantity) || newQuantity <= 0) {
      newQuantity = 1; // Default to 1 if invalid
    }

    setQuantities((prevQuantities) => {
      const updatedQuantities = [...prevQuantities];
      updatedQuantities[index] = newQuantity;
      return updatedQuantities;
    });

    setCylinders((prevCylinders) => {
      const updatedCylinders = [...prevCylinders];
      if (updatedCylinders[index]) {
        updatedCylinders[index].quantity = newQuantity;
      }
      return updatedCylinders;
    });
  };

  const removeItem = (indexToRemove) => {
    setCylinders((prevCylinders) => {
      const updatedCylinders = prevCylinders.filter(
        (_, idx) => idx !== indexToRemove
      );
      return updatedCylinders;
    });
    setQuantities((prevQuantities) => {
      const updatedQuantities = prevQuantities.filter(
        (_, idx) => idx !== indexToRemove
      );
      return updatedQuantities;
    });
  };

  const closeModal = () => {
    setShowModal(false);
    setCheckoutStep(1);
    // Do not reset paymentStatus or paymentStatusMessage here
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("modalOverlay")) {
      closeModal();
    }
  };

  const proceedToPayment = async () => {
    // Validate billing details
    const requiredFields = ["name", "email", "address", "city", "postalCode", "country"];
    for (const field of requiredFields) {
      if (!billingDetails[field]) {
        setErrorMessage("Please fill in all required fields.");
        return;
      }
    }

    // Fetch shipping options
    setIsLoadingShippingOptions(true);
    try {
      const options = await calculateShippingCost({
        name: billingDetails.name,
        address: billingDetails.address,
        city: billingDetails.city,
        postalCode: billingDetails.postalCode,
        country: billingDetails.country,
        totalWeight,
        totalValue: totalPrice,
      });

      if (options.length === 0) {
        setErrorMessage("No shipping options available for your address.");
        setIsLoadingShippingOptions(false);
        return;
      }

      setShippingOptions(options);
      setSelectedShippingOption(options[0]); // Select the first option by default
      setIsLoadingShippingOptions(false);
      setCheckoutStep(3);
    } catch (error) {
      console.error("Error fetching shipping options:", error);
      setErrorMessage(
        "Error fetching shipping options. Please check your details and try again."
      );
      setIsLoadingShippingOptions(false);
    }
  };

  return (
    <>
      {/* Sticky Cylinder UI */}
      <div className="stickyIsland" onClick={() => setShowModal(true)}>
        <p className="cartLabel">Cart:</p>
        <div className="flexContainer">
          {cylinders
            .slice()
            .reverse()
            .slice(0, 5)
            .map((cylinder, index) => (
              <div key={index} className="bottleWrapper">
                {cylinder && (
                  <BottleWithPerfume
                    colorTone={bottleColors[index % bottleColors.length]}
                    fillLevel={fillLevel}
                  />
                )}
              </div>
            ))}
        </div>
      </div>

      {/* Success Notification Display */}
      {(showSuccessMessage || paymentStatus === "error") &&
        paymentStatusMessage && (
          <div className="fixed top-[40px] left-0 w-full flex justify-center z-9999 px-2">
            <div
              className={`p-3 rounded-md shadow-lg mt-4 ${
                paymentStatus === "success" || showSuccessMessage
                  ? "bg-green-500 text-white"
                  : "bg-red-500 text-white"
              } max-w-xl w-full`}
            >
              <p className="text-center text-sm md:text-base">
                {paymentStatusMessage}
              </p>
            </div>
          </div>
        )}

      {/* Modal Overlay for Review and Checkout */}
      {showModal && (
        <div className="modalOverlay" onClick={handleOutsideClick}>
          <div className="modalContentFullScreen">
            {checkoutStep === 1 && (
              <>
                {/* Step 1: Review Order */}
                <h2 className="text-xl font-bold mb-4">Review Your Order</h2>
                <div className="cylinders-review">
                  {cylinders.map(
                    (cylinder, index) =>
                      cylinder && (
                        <div
                          key={index}
                          className="product-item mb-4 flex productItem"
                        >
                          {/* Product details */}
                          <img
                            src={`/uploads/${cylinder.gender}/${cylinder.brand
                              .replace(/\s+/g, "_")
                              .toLowerCase()}/${cylinder.model
                              .replace(/\s+/g, "_")
                              .toLowerCase()}/${cylinder.images[0]}`}
                            alt={`${cylinder.brand} ${cylinder.model}`}
                            className="bottleImage"
                          />
                          <div className="productDetails">
                            <p className="productName">
                              <strong>
                                {cylinder.brand.replace(/_/g, " ")},{" "}
                                {cylinder.model.replace(/_/g, " ")}
                              </strong>
                            </p>
                            <p>
                              {cylinder.selectedSize.size}ml: €
                              {cylinder.selectedSize.price}
                            </p>
                            <div className="quantity">
                              <label htmlFor={`quantity-${index}`}>Qty: </label>
                              <select
                                id={`quantity-${index}`}
                                value={quantities[index]}
                                onChange={(e) =>
                                  handleQuantityChange(index, e.target.value)
                                }
                                className="quantitySelect"
                              >
                                {[...Array(10).keys()].map((i) => (
                                  <option key={i + 1} value={i + 1}>
                                    {i + 1}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <p>
                              Total: €
                              {(
                                cylinder.selectedSize.price * quantities[index]
                              ).toFixed(2)}
                            </p>
                          </div>
                          <button
                            onClick={() => removeItem(index)}
                            className="bg-red-500 text-white p-2 rounded-md hover:bg-red-600 removeButton"
                          >
                            X
                          </button>
                        </div>
                      )
                  )}
                </div>
                <div className="totalPrice">
                  <h3>Total Items: {totalItems}</h3>
                  <h3>Total Price: €{totalPrice.toFixed(2)}</h3>
                </div>
                <div className="actions mt-4 actionButtons">
                  <button
                    className="bg-green-500 text-white p-2 px-4 rounded-md mr-2"
                    onClick={() => setCheckoutStep(2)}
                  >
                    Proceed to Enter Address
                  </button>
                  <button
                    className="bg-gray-500 text-white p-2 px-4 rounded-md"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </>
            )}

            {checkoutStep === 2 && (
              <>
                {/* Step 2: Enter Client Info */}
                <ClientInfoForm
                  billingDetails={billingDetails}
                  setBillingDetails={setBillingDetails}
                />
                {errorMessage && (
                  <div className="mt-4 text-red-600">{errorMessage}</div>
                )}
                <div className="actions mt-4 actionButtons">
                  <button
                    className="bg-blue-500 text-white p-2 px-4 rounded-md mr-2"
                    onClick={proceedToPayment}
                    disabled={isLoadingShippingOptions}
                  >
                    {isLoadingShippingOptions
                      ? "Loading Shipping Options..."
                      : "Proceed to Payment"}
                  </button>
                  <button
                    className="bg-gray-500 text-white p-2 px-4 rounded-md"
                    onClick={() => setCheckoutStep(1)}
                  >
                    ← Back to Review
                  </button>
                </div>
              </>
            )}

            {checkoutStep === 3 && (
              <>
                {/* Step 3: Payment */}
                <PaymentForm
                  billingDetails={billingDetails}
                  quantities={quantities}
                  setPaymentStatus={setPaymentStatus}
                  setPaymentStatusMessage={setPaymentStatusMessage}
                  cylinders={cylinders}
                  shippingOptions={shippingOptions}
                  selectedShippingOption={selectedShippingOption}
                  setSelectedShippingOption={setSelectedShippingOption}
                />

                {/* Display only error messages inside the form */}
                {paymentStatus === "error" && paymentStatusMessage && (
                  <div className="mt-4 text-red-600">
                    {paymentStatusMessage}
                  </div>
                )}
                {/* Actions based on payment status */}
                {paymentStatus !== "success" && (
                  <div className="actions mt-4 actionButtons">
                    <button
                      className="bg-gray-500 text-white p-2 px-4 rounded-md"
                      onClick={() => setCheckoutStep(2)}
                    >
                      ← Back to Enter Address
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default StickyCylinders;
