// index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import AppContent from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { SessionProvider } from './context/SessionContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ModalProvider } from './context/ModalContext';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const stripePromise = loadStripe('pk_test_51Q28hI04EEny9SG0rN6iGuSYqI96jIahEpQk0eU7I64L13qvFwgPX3Uh0cLUSIHQ8lh9DZFrkbvUSunshatK6fKU00IjssF3Qs');

root.render(
  <SessionProvider>
    <Router>
      <Elements stripe={stripePromise}>
        <ModalProvider>
          <AppContent />
        </ModalProvider>
      </Elements>
    </Router>
  </SessionProvider>
);
