import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LiveVisits from './LiveVisits'; // Import the LiveVisits component

const AnalyticsSummary = () => {
  const [summaryData, setSummaryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedTimeSpan, setSelectedTimeSpan] = useState('allTime');

  useEffect(() => {
    fetchSummaryData(selectedTimeSpan);
  }, [selectedTimeSpan]);

  const fetchSummaryData = async (timespan) => {
    try {
      const response = await axios.get('/api/analytics/summary-with-time-spans', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      });
      setSummaryData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching analytics summary data with time spans:', error);
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="text-center text-warmSand">Loading...</div>;
  }

  if (!summaryData) {
    return <div className="text-center text-warmSand">No data available</div>;
  }

  return (
    <div className="w-full flex items-center justify-between p-4 bg-lavaBlack text-silverGray shadow-md mb-6">
      {/* Current Live Visits - Move the LiveVisits component here */}
      <LiveVisits />

      {/* Analytics Summary with Time Span Selection */}
      <div className="flex items-center space-x-4">
        <div className="text-lg font-bold">Analytics Summary</div>
        <select
          className="bg-warmSand text-lavaBlack p-2 rounded-md"
          value={selectedTimeSpan}
          onChange={(e) => setSelectedTimeSpan(e.target.value)}
        >
          <option value="allTime">All Time</option>
          <option value="lastThreeMonths">Last 3 Months</option>
          <option value="lastMonth">Last Month</option>
          <option value="lastWeek">Last Week</option>
          <option value="lastDay">Last Day</option>
        </select>
        <div className="text-lg">
          Total Site Visits: {summaryData.totalVisits[selectedTimeSpan]}
        </div>
      </div>
    </div>
  );
};

export default AnalyticsSummary;
