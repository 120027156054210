import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="custom-modal-content-container">
      <p className="custom-modal-effective-date">Effective date: 15 October 2024</p>

      <h2 className="custom-modal-subheader">1. Introduction</h2>
      <p className="custom-modal-paragraph">
        These terms and conditions govern your use of our website and services. By placing an order, you agree to the following terms.
      </p>
      <p className="custom-modal-paragraph">
        At ttScent, we offer sample-sized versions of popular fragrances for personal testing and scent exploration. Our products are not intended as full retail packages, but as smaller sample formats to help you find a scent you love.
      </p>


      <h2 className="custom-modal-subheader">2. Orders and Payments</h2>
      <p className="custom-modal-paragraph">
        Payments are processed securely via third-party providers (e.g., Stripe). We do not store your payment details.
      </p>

      <h2 className="custom-modal-subheader">3. Shipping and Delivery</h2>
      <p className="custom-modal-paragraph">
        We aim to deliver within 14 days. Shipping is handled by trusted partners such as PostNord, DHL, etc.
      </p>

      <h2 className="custom-modal-subheader">4. Refunds</h2>
      <p className="custom-modal-paragraph">
        We do not provide refunds.
      </p>

      <h2 className="custom-modal-subheader">5. Liability</h2>
      <p className="custom-modal-paragraph">
        We are not responsible for delays caused by third-party shipping services or unforeseen circumstances.
      </p>

      <h2 className="custom-modal-subheader">6. Changes to Terms</h2>
      <p className="custom-modal-paragraph">
        We may update these terms at any time. The latest version will be available on this page.
      </p>

      <h2 className="custom-modal-subheader">7. Contact Us</h2>
      <p className="custom-modal-paragraph">
        If you have any questions, contact us at support@ttscent.com
      </p>
    </div>
  );
};

export default TermsAndConditions;
