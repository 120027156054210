// useAnalytics.js

import { useCallback } from 'react';
import trackEvent from '../api/analytics';

const useAnalytics = () => {
  const logEvent = useCallback((eventName, eventData = {}) => {
    trackEvent(eventName, eventData);
  }, []);

  return { logEvent };
};

export default useAnalytics;
  