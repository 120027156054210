// src/components/Products.js

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "../context/SessionContext";
import trackEvent from "../api/analytics";
import { debounce } from "lodash";
import "./Products.css";
import loadingGif from "../assets/loading3.gif"; 


const Products = () => {
  const { addCylinder, cylinders } = useSession();
  const [products, setProducts] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState({});
  const [gender, setGender] = useState("all");
  const [sortBy, setSortBy] = useState("default"); // Changed default to 'default'
  const [brandFilter, setBrandFilter] = useState("all");
  const [seasonFilter, setSeasonFilter] = useState("all");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [error, setError] = useState(null); // New state for error handling
  const navigate = useNavigate();

  const debouncedTrackProductView = debounce((productId) => {
    trackEvent("product_view", { productId });
  }, 300);

  const fetchProducts = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      // Construct query parameters based on current filters and sorting
      const params = new URLSearchParams();

      if (gender !== "all") params.append("gender", gender);
      if (brandFilter !== "all") params.append("brand", brandFilter);
      if (seasonFilter !== "all") params.append("season", seasonFilter);
      if (sortBy !== "default") params.append("sortBy", sortBy);

      const response = await fetch(`/api/products?${params.toString()}`);
      if (!response.ok) {
        throw new Error("Failed to fetch products.");
      }
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error("Error fetching products:", error);
      setError("Failed to load products. Please try again later.");
      setProducts([]);
    } finally {
      setLoading(false);
    }
  }, [gender, brandFilter, seasonFilter, sortBy]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleViewProductDetails = (productId) => {
    debouncedTrackProductView(productId);
    navigate(`/products/${productId}`);
  };

  const handleAddCylinder = (product, size) => {
    const totalAddedQuantity = cylinders
      .filter((c) => c && c._id === product._id)
      .reduce((total, c) => total + (c.quantity || 1) * c.selectedSize.size, 0);

    const availableStock = product.stock - totalAddedQuantity;

    if (size.size > availableStock) {
      setModalMessage(`Sorry, only ${availableStock}ml left for ${product.model}.`);
      setShowModal(true);
      setTimeout(() => setShowModal(false), 3000);
      return;
    }

    addCylinder({ ...product, selectedSize: size });
    setModalMessage(
      `${product.brand} ${product.model} (${size.size}ml) added to cart.`
    );
    setShowModal(true);
    setTimeout(() => setShowModal(false), 3000);
  };

  const handleSelectSize = (product, size) => {
    setSelectedSizes((prevSizes) => ({
      ...prevSizes,
      [product._id]: size,
    }));
    handleAddCylinder(product, size);
  };

  const handleRequestStock = async (productId) => {
    try {
      const apiUrl =
        process.env.NODE_ENV === "development"
          ? `http://localhost:5000/api/products/${productId}/request-stock`
          : `/api/products/${productId}/request-stock`;

      const response = await fetch(apiUrl, {
        method: "POST",
      });

      if (!response.ok) {
        throw new Error("Failed to log interest");
      }

      const updatedProductResponse = await fetch(`/api/products/${productId}`);
      const updatedProduct = await updatedProductResponse.json();

      setProducts((prevProducts) =>
        prevProducts.map((prod) =>
          prod._id === productId ? updatedProduct : prod
        )
      );

      setModalMessage("Your interest has been logged. Thank you!");
      setShowModal(true);
      setTimeout(() => setShowModal(false), 3000);
    } catch (error) {
      console.error("Error logging interest:", error);
      setModalMessage("Failed to log interest. Please try again.");
      setShowModal(true);
      setTimeout(() => setShowModal(false), 3000);
    }
  };

  // Update fetchProducts dependency array
  // Now it depends on gender, brandFilter, seasonFilter, sortBy

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <img src={loadingGif} alt="Loading..." className="w-32 h-32" />
      </div>
    );
  }
  // Handle error display
  if (error) {
    return (
      <div className="text-center text-red-500">
        {error}
      </div>
    );
  }

  const formatBrandOrModelName = (name) => name.replace(/_/g, " ");

  // Split products into left and right columns for mobile layout
  const leftColumnItems = [];
  const rightColumnItems = [];

  products.forEach((product, index) => {
    if (index % 2 === 0) {
      leftColumnItems.push(product);
    } else {
      rightColumnItems.push(product);
    }
  });

  return (
    <div className="products bg-lavaBlack text-silverGray p-4 lg:p-8 min-h-screen">
      <h1 className="text-3xl lg:text-4xl font-bold mb-4 lg:mb-6 text-center">
        Our Products
      </h1>

      {showModal && (
        <div className="fixed top-[40px] left-0 w-full flex justify-center z-50">
          <div
            className={`p-3 rounded-md shadow-lg mt-4 ${
              modalMessage.includes("added") || modalMessage.includes("logged")
                ? "bg-green-500 text-white"
                : "bg-red-500 text-white"
            }`}
          >
            <p>{modalMessage}</p>
          </div>
        </div>
      )}

      {/* Responsive Container */}
      <div className="max-w-6xl mx-auto">
        {/* Desktop Grid Layout */}
        <div className="hidden md:grid grid-cols-2 md:grid-cols-3 gap-6">
          {/* Filters */}
          <div className="md:col-span-3 mb-6">
            <div className="flex flex-wrap items-center justify-center space-x-4">
              {/* Gender Filter */}
              <div className="flex items-center space-x-2 text-sm lg:text-lg">
                <label className="font-semibold">Gender:</label>
                <select
                  className="bg-lavaBlack text-warmSand p-1 rounded-md"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="all">All genders</option>
                  <option value="manly">Masculine</option>
                  <option value="feminine">Feminine</option>
                  <option value="unisex">Unisex</option>
                </select>
              </div>

              {/* Sort by Filter */}
              <div className="flex items-center space-x-2 text-sm lg:text-lg">
                <label className="font-semibold">Sort by:</label>
                <select
                  className="bg-lavaBlack text-warmSand p-1 rounded-md"
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  <option value="default">Default</option>
                  <option value="bestSelling">Best Selling</option>
                  <option value="cheapest">Cheapest</option>
                  <option value="newArrival">New Arrival</option>
                </select>
              </div>

              {/* Brand Filter */}
              <div className="flex items-center space-x-2 text-sm lg:text-lg">
                <label className="font-semibold">Brand:</label>
                <select
                  className="bg-lavaBlack text-warmSand p-1 rounded-md"
                  value={brandFilter}
                  onChange={(e) => setBrandFilter(e.target.value)}
                >
                  <option value="all">All Brands</option>
                  {Array.from(new Set(products.map((p) => p.brand))).map(
                    (brand) => (
                      <option key={brand} value={brand}>
                        {formatBrandOrModelName(brand)}
                      </option>
                    )
                  )}
                </select>
              </div>

              {/* Season Filter */}
              <div className="flex items-center space-x-2 text-sm lg:text-lg">
                <label className="font-semibold">Season:</label>
                <select
                  className="bg-lavaBlack text-warmSand p-1 rounded-md"
                  value={seasonFilter}
                  onChange={(e) => setSeasonFilter(e.target.value)}
                >
                  <option value="all">Any Season</option>
                  <option value="Winter">Winter</option>
                  <option value="Summer">Summer</option>
                  <option value="All Seasons">All Seasons</option>
                </select>
              </div>
            </div>
          </div>

          {/* Product Cards */}
          {products.map((product) => (
            <div
              key={product._id}
              className="bg-warmSand p-4 rounded-lg shadow-md text-center cursor-pointer"
              onClick={() => handleViewProductDetails(product._id)}
            >
              {/* Product content */}
              <div className="relative w-full pb-[100%] bg-white rounded-lg mx-auto overflow-hidden flex justify-center items-center">
                <img
                  src={`/uploads/${product.gender}/${product.brand
                    .replace(/\s+/g, "_")
                    .toLowerCase()}/${product.model
                    .replace(/\s+/g, "_")
                    .toLowerCase()}/${product.images[0]}`}
                  alt={product.model}
                  className="absolute top-0 left-0 w-full h-full object-contain"
                />
              </div>

              <div className="mt-2">
                <h2 className="text-sm md:text-base lg:text-lg font-bold text-lavaBlack">
                  {formatBrandOrModelName(product.brand)},{" "}
                  {formatBrandOrModelName(product.model)}
                </h2>

                <p className="text-xs md:text-sm text-lavaBlack mt-1">
                  Best for: {product.season} | Gender:{" "}
                  {product.gender === "manly" ? "Masculine" : product.gender}
                </p>

                {product.stock <= 25 && product.stock > 0 && (
                  <p className="low-stock-warning text-red-600 text-xs md:text-sm">
                    Hurry! Only {product.stock}ml left in stock!
                  </p>
                )}
                {product.stock === 0 && (
                  <div className="flex justify-center items-center space-x-2">
                    <p className="text-xs font-bold text-red-600">
                      Out of Stock
                    </p>
                    <button
                      className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded-md text-xs"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRequestStock(product._id);
                      }}
                    >
                      Request
                    </button>
                  </div>
                )}

                {selectedSizes[product._id] ? (
                  <p className="text-xs text-lavaBlack mt-1">
                    Selected Size: {selectedSizes[product._id].size}ml - €
                    {selectedSizes[product._id].price}
                  </p>
                ) : (
                  <p className="text-xs text-lavaBlack mt-1">
                    Choose a size to add:
                  </p>
                )}

                <div className="mt-2 grid grid-cols-2 gap-1 sm:flex sm:justify-center sm:space-x-2">
                  {product.sizes.map((size, idx) => {
                    const isOutOfStock = product.stock < size.size;
                    return (
                      <button
                        key={idx}
                        className={`${
                          isOutOfStock
                            ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                            : "bg-lavaBlack text-warmSand hover:bg-gray-700"
                        } py-1 px-2 rounded-md transition-all w-full md:w-auto price-button price-button-desktop`}
                        disabled={isOutOfStock}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!isOutOfStock) {
                            handleSelectSize(product, size);
                          }
                        }}
                      >
                        {size.size}ml - €{size.price}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Mobile Layout */}
        <div className="md:hidden">
          <div className="flex gap-4">
            {/* Left Column */}
            <div className="w-1/2 flex flex-col space-y-4">
              {leftColumnItems.map((product) => (
                <div
                  key={product._id}
                  className="bg-warmSand p-2 rounded-lg shadow-md text-center cursor-pointer"
                  onClick={() => handleViewProductDetails(product._id)}
                >
                  {/* Product content */}
                  <div className="relative w-full pb-[100%] bg-white rounded-lg mx-auto overflow-hidden flex justify-center items-center">
                    <img
                      src={`/uploads/${product.gender}/${product.brand
                        .replace(/\s+/g, "_")
                        .toLowerCase()}/${product.model
                        .replace(/\s+/g, "_")
                        .toLowerCase()}/${product.images[0]}`}
                      alt={product.model}
                      className="absolute top-0 left-0 w-full h-full object-contain"
                    />
                  </div>

                  <div className="mt-2">
                    <h2 className="text-sm font-bold text-lavaBlack">
                      {formatBrandOrModelName(product.brand)},{" "}
                      {formatBrandOrModelName(product.model)}
                    </h2>

                    <p className="text-xs text-lavaBlack mt-1">
                      Best for: {product.season} | Gender:{" "}
                      {product.gender === "manly"
                        ? "Masculine"
                        : product.gender}
                    </p>

                    {product.stock <= 25 && product.stock > 0 && (
                      <p className="low-stock-warning text-red-600 text-xs">
                        Hurry! Only {product.stock}ml left in stock!
                      </p>
                    )}
                    {product.stock === 0 && (
                      <div className="flex justify-center items-center space-x-2">
                        <p className="text-xs font-bold text-red-600">
                          Out of Stock
                        </p>
                        <button
                          className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded-md text-xs"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRequestStock(product._id);
                          }}
                        >
                          Request
                        </button>
                      </div>
                    )}

                    {selectedSizes[product._id] ? (
                      <p className="text-xs text-lavaBlack mt-1">
                        Selected Size: {selectedSizes[product._id].size}ml - €
                        {selectedSizes[product._id].price}
                      </p>
                    ) : (
                      <p className="text-xs text-lavaBlack mt-1">
                        Choose a size to add:
                      </p>
                    )}

                    <div className="mt-2 grid grid-cols-2 gap-1 sm:flex sm:justify-center sm:space-x-2">
                      {product.sizes.map((size, idx) => {
                        const isOutOfStock = product.stock < size.size;
                        return (
                          <button
                            key={idx}
                            className={`${
                              isOutOfStock
                                ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                                : "bg-lavaBlack text-warmSand hover:bg-gray-700"
                            } p-1 rounded-md transition-all w-full text-xs`}
                            disabled={isOutOfStock}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!isOutOfStock) {
                                handleSelectSize(product, size);
                              }
                            }}
                          >
                            {size.size}ml - €{size.price}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Right Column */}
            <div className="w-1/2 flex flex-col space-y-4">
              {/* Filters at the top */}
              <div className="filters-container bg-lavaBlack p-2 rounded-lg shadow-md mb-4">
                <div className="flex flex-col space-y-2">
                  {/* Gender Filter */}
                  <select
                    className="bg-lavaBlack text-warmSand p-1 rounded-md w-full"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="all">All genders</option>
                    <option value="manly">Masculine</option>
                    <option value="feminine">Feminine</option>
                    <option value="unisex">Unisex</option>
                  </select>

                  {/* Sort by Filter */}
                  <select
                    className="bg-lavaBlack text-warmSand p-1 rounded-md w-full"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                  >
                    <option value="default">Default</option>
                    <option value="bestSelling">Best Selling</option>
                    <option value="cheapest">Cheapest</option>
                    <option value="newArrival">New Arrival</option>
                  </select>

                  {/* Brand Filter */}
                  <select
                    className="bg-lavaBlack text-warmSand p-1 rounded-md w-full"
                    value={brandFilter}
                    onChange={(e) => setBrandFilter(e.target.value)}
                  >
                    <option value="all">All Brands</option>
                    {Array.from(new Set(products.map((p) => p.brand))).map(
                      (brand) => (
                        <option key={brand} value={brand}>
                          {formatBrandOrModelName(brand)}
                        </option>
                      )
                    )}
                  </select>

                  {/* Season Filter */}
                  <select
                    className="bg-lavaBlack text-warmSand p-1 rounded-md w-full"
                    value={seasonFilter}
                    onChange={(e) => setSeasonFilter(e.target.value)}
                  >
                    <option value="all">Any Season</option>
                    <option value="Winter">Winter</option>
                    <option value="Summer">Summer</option>
                    <option value="All Seasons">All Seasons</option>
                  </select>
                </div>
              </div>

              {rightColumnItems.map((product) => (
                <div
                  key={product._id}
                  className="bg-warmSand p-2 rounded-lg shadow-md text-center cursor-pointer"
                  onClick={() => handleViewProductDetails(product._id)}
                >
                  {/* Product content */}
                  <div className="relative w-full pb-[100%] bg-white rounded-lg mx-auto overflow-hidden flex justify-center items-center">
                    <img
                      src={`/uploads/${product.gender}/${product.brand
                        .replace(/\s+/g, "_")
                        .toLowerCase()}/${product.model
                        .replace(/\s+/g, "_")
                        .toLowerCase()}/${product.images[0]}`}
                      alt={product.model}
                      className="absolute top-0 left-0 w-full h-full object-contain"
                    />
                  </div>

                  <div className="mt-2">
                    <h2 className="text-sm font-bold text-lavaBlack">
                      {formatBrandOrModelName(product.brand)},{" "}
                      {formatBrandOrModelName(product.model)}
                    </h2>

                    <p className="text-xs text-lavaBlack mt-1">
                      Best for: {product.season} | Gender:{" "}
                      {product.gender === "manly"
                        ? "Masculine"
                        : product.gender}
                    </p>

                    {product.stock <= 25 && product.stock > 0 && (
                      <p className="low-stock-warning text-red-600 text-xs">
                        Hurry! Only {product.stock}ml left in stock!
                      </p>
                    )}
                    {product.stock === 0 && (
                      <div className="flex justify-center items-center space-x-2">
                        <p className="text-xs font-bold text-red-600">
                          Out of Stock
                        </p>
                        <button
                          className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded-md text-xs"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRequestStock(product._id);
                          }}
                        >
                          Request
                        </button>
                      </div>
                    )}

                    {selectedSizes[product._id] ? (
                      <p className="text-xs text-lavaBlack mt-1">
                        Selected Size: {selectedSizes[product._id].size}ml - €
                        {selectedSizes[product._id].price}
                      </p>
                    ) : (
                      <p className="text-xs text-lavaBlack mt-1">
                        Choose a size to add:
                      </p>
                    )}

                    <div className="mt-2 grid grid-cols-2 gap-1 sm:flex sm:justify-center sm:space-x-2">
                      {product.sizes.map((size, idx) => {
                        const isOutOfStock = product.stock < size.size;
                        return (
                          <button
                            key={idx}
                            className={`${
                              isOutOfStock
                                ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                                : "bg-lavaBlack text-warmSand hover:bg-gray-700"
                            } p-1 md:p-2 lg:p-3 rounded-md transition-all w-full md:w-auto text-xs md:text-sm lg:text-base`}
                            disabled={isOutOfStock}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!isOutOfStock) {
                                handleSelectSize(product, size);
                              }
                            }}
                          >
                            {size.size}ml - €{size.price}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
